import { Component, OnInit, Output, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { IResearch } from '@model/collection-research';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);

  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  private _title: Title;

  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild('researchDiv', { static: true }) simple;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    accessibility: false,
    nextArrow: '<button class="slick-next" aria-label="Next" type="button">Next</button>',
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public research: IResearch = {
    id: 0,
    name: "",
    html: "",
    imgs: [],
    annexFileList: null
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this.research.id = Number(activatedRoute.snapshot.paramMap.get('id'));
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("");

    const getEnglishId = {
      type: 3,
      Id: this.research.id,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Exhibit/getEnglishExhibitId', getEnglishId).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        if (response['data'].isChangeLang) {
          if (response['data'].id !== 0) {
            this.research.id = response['data'].id;
            this._router.navigate(['/ResearchList/' + response['data'].id]);
          }

          this._http.post(this._baseUrl + 'CollectionResearch/SearchResearch', this.research.id).subscribe((response) => {

            if (response['codeThird'] === "0000") {
              this.research.id = response['data'].id;
              this.research.name = response['data'].name;
              this.simple.nativeElement.innerHTML = response['data'].html;
              this.research.imgs = response['data'].imgs;
              this.research.annexFileList = response['data'].annexFileList;
            }
            else {
              Swal.fire({
                title: response['msg'],
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
              }).then((result) => {
                this._router.navigate(['/ResearchList']);
              });
            }
          });
        }
        else {
          this._router.navigate(['/ResearchList']);
        }
      }
      else {
        Swal.fire({
          title: "系統發生錯誤請聯絡纖博",
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/ResearchList']);
        });
      }
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 研究");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Research");
    }
  }

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
}
