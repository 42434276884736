import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-visit-instructions',
  templateUrl: './visit-instructions.component.html',
  styleUrls: ['./visit-instructions.component.css']
})
export class VisitInstructionsComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public detailNos: any[];
  public visitInstructions: any[];

  public Lang: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("visit");
    this.navSel.emit("/VisitInstructions");

    this.Lang = localStorage.getItem('Language');

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 參觀須知");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Visitor Notice");
    }

    const filter = {
      type: 2,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Visit/GetDetailNo', null).subscribe((response) => {
      this.detailNos = response['data'];
    });

    this._http.post(this._baseUrl + 'Visit/SearchVisit', filter).subscribe((response) => {
      this.visitInstructions = response['data'];
    });
  }

  GetDetailNoText(key: number) {
    let text: string = "";
    if (key != 99) {
      text = this.detailNos.find(x => x.key == key).text;
    }

    return text;
  }

}
