import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-collection-policy',
  templateUrl: './collection-policy.component.html',
  styleUrls: ['./collection-policy.component.css']
})
export class CollectionPolicyComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  public collectionPolicy: string = "";
  private _title: Title;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("collect");
    this.navSel.emit("/CollectionPolicy");
    this.Lang = localStorage.getItem('Language');
    const filter = {
      type: 7,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Volunteer/InitAbout', filter).subscribe((response) => {
      this.collectionPolicy = response['data'].html;
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 臺中市纖維工藝博物館文物蒐藏作業要點");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Taichung Museum of Fiber Arts Regulations for the Acquisition of Cultural Relics");
    }
  }
}
