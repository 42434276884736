import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';
// Datepicker Module
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import * as _moment from 'moment';
import { default as _rollupMoment } from 'moment';

const moment = _rollupMoment || _moment;
export const TW_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM/DD'
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'YYYY MMM'
  }
};

@Component({
  selector: 'app-reservation-guided',
  templateUrl: './reservation-guided.component.html',
  styleUrls: ['./reservation-guided.component.css', '../../../assets/CustomizationMofia/commons/page/css/form.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },
    { provide: MAT_DATE_FORMATS, useValue: TW_FORMATS },
  ],
})
export class ReservationGuidedComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public _ReservationImportant: string;
  public formReservationGuided: FormGroup;
  public timecheck = true;
  public timerangecheck = true;
  private _minVisitDate: Date;
  public ReservationGuided = {
    applicationUnitName: "", //申請單位名稱
    applicationUnitAddr: "", //申請單位地址
    visitorsType: "1", //參訪成員
    visitorsOther: "", //參訪成員(其他)
    visitNumber: "0", //參觀人數
    visitFinish: "", //參觀狀態
    visitDate: "", //參觀日期
    visitStartTime: "", //起始時間
    visitEndTime: "", //結束時間
    leaderName: "", //領隊姓名
    leaderPosition: "", //領隊職稱
    contactPersonName: "", //聯絡人
    contactPersonPosition: "", //聯絡人職稱
    contactTel: "", //辦公室電話
    contactPhone: "", //手機號碼
    email: "", //電子信箱
    remark: "", //備註
  };
  private judgeEmail: boolean; //電子信箱是否合法
  private judgeContactPhone: boolean; //手機號碼是否合法

  public Lang: string;
  private _title: Title;

  public timeVisit: string;
  public timeVisitStartTime: string;
  public timeVisitEndTime: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("visit");
    this.navSel.emit("/ReservationGuided");

    this.Lang = localStorage.getItem('Language');

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 預約導覽");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Tour Reservation");
    }

    const filter = {
      type: 4,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Visit/SearchVisit', filter).subscribe((response) => {
      this._ReservationImportant = response['data'][0].html;
    });

    this.formReservationGuided = this.fb.group({
      applicationUnitName: [null, [Validators.required]],
      applicationUnitAddr: [null, [Validators.required]],
      raoVisitorsType: [''],
      visitorsOther: [''],
      visitNumber: [null, [Validators.required, Validators.min(0)]],
      raoVisitFinish: [''],
      visitDate: [null, [Validators.required]],
      raotimeVisit: [''],
      visitStartTime: [null, [Validators.required]],
      visitEndTime: [null, [Validators.required]],
      leaderName: [null, [Validators.required]],
      leaderPosition: [null, [Validators.required]],
      contactPersonName: [null, [Validators.required]],
      contactPersonPosition: [null, [Validators.required]],
      contactTel: [null, [Validators.required]],
      contactPhone: [null, [Validators.required, Validators.pattern(new RegExp("09[0-9]{8}"))]],
      email: [null, [Validators.required, Validators.pattern(new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"))]],
      remark: ['']
    });

    this._minVisitDate = new Date();
    this._minVisitDate.setDate(this._minVisitDate.getDate() + 15);

    this.timeVisit = "1";
    this.timeVisitStartTime = "00:00";
    this.timeVisitEndTime = "11:59";
  }

  //參觀日期指定星期不可選擇
  familyDayFilter(date: moment.Moment): boolean {
    const day = date.day();
    return day !== 1 && day !== 6 && day !== 0;
  }

  //驗證信箱格式
  public blurEmail() {
    var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (pattern.test(this.ReservationGuided.email)) {
      this.judgeEmail = true;
    }
    else {
      this.judgeEmail = false;
    }
  }

  //驗證手機號碼格式
  public blurContactPhone() {
    this.ReservationGuided.contactPhone = this.ReservationGuided.contactPhone.replace("-", "");
    var pattern = /^09\d{2}?\d{3}-?\d{3}$/;
    if (pattern.test(this.ReservationGuided.contactPhone)) {
      this.judgeContactPhone = true;
    }
    else {
      this.judgeContactPhone = false;
    }
  }
  public check() {
    var start_time = this.formReservationGuided.get('visitStartTime').value;
    if (start_time != null && start_time != "") {
      var start_time_split = start_time.split(":");
      var start_time_hour = Number(start_time_split[0]);
      var start_time_min = Number(start_time_split[1]);
      if ((this.timeVisit == "1" && (start_time_hour >= 9 && start_time_hour <= 12)) || (this.timeVisit == "2" && (start_time_hour >= 13 && start_time_hour <= 15))) {
        if ((start_time_hour == 12 || start_time_hour == 15) && start_time_min > 0) {
          this.timecheck = false;
          return;
        } else {
          this.timecheck = true;
        }
      } else {
        this.timecheck = false;
        return;
      }

      //if (this.timeVisit == "1") {
      //  if (start_time_hour > 12 || start_time_hour < 0) {
      //    this.timecheck = false;
      //  }
      //}
      //else if (this.timeVisit == "2") {
      //  if (start_time_hour < 12 || start_time_hour > 23) {
      //    this.timecheck = false;
      //  }
      //}
    }

    var end_time = this.formReservationGuided.get('visitEndTime').value;
    if (end_time != null && end_time != "") {
      var end_time_split = end_time.split(":");
      var end_time_hour = Number(end_time_split[0]);
      var end_time_min = Number(end_time_split[1]);

      if ((this.timeVisit == "1" && (end_time_hour >= 9 && end_time_hour <= 12)) || (this.timeVisit == "2" && (end_time_hour >= 13 && end_time_hour <= 15))) {
        if ((end_time_hour == 12 || end_time_hour == 15) && end_time_min > 0) {
          this.timecheck = false;
          return;
        } else {
          this.timecheck = true;
        }
      } else {
        this.timecheck = false;
        return;
      }
    }
    //if (this.timerangecheck) {
    //  if (start_time_hour > 12) {
    //    this.ReservationGuided.visitEndTime = "15:00";
    //  }
    //  else if (start_time_hour <= 12) {
    //    this.ReservationGuided.visitEndTime = "12:00";
    //  }
    //} else {
    //  this.ReservationGuided.visitEndTime = null;
    //}


    if ((this.ReservationGuided.visitEndTime != null && this.ReservationGuided.visitEndTime != "")
      && (this.ReservationGuided.visitStartTime != null && this.ReservationGuided.visitStartTime != "")) {
      if (Date.parse("2020-10-07T" + this.formReservationGuided.get('visitEndTime').value) - Date.parse("2020-10-07T" + this.formReservationGuided.get('visitStartTime').value) < 0) {
        this.timecheck = false;
      }
      else {
        this.timecheck = true;
      }
    } else {
      this.timecheck = false;
    }
  }

  //public changetimeVisit() {
  //  if (this.timeVisit == "2") { //下午
  //    this.timeVisitStartTime = "12:00";
  //    this.timeVisitEndTime = "23:59";
  //  } else { //上午
  //    this.timeVisitStartTime = "00:00";
  //    this.timeVisitEndTime = "11:59";
  //  }
  //}

  //儲存預約導覽
  SaveReservationGuided() {
    const reservationGuidedForm = this.ReservationGuided;

    if (!this.formReservationGuided.valid || !this.timecheck) {
      this.scrollToError();
      return;
    }
    //console.log(reservationGuidedForm);
    //console.log(this.ReservationGuided);
    this._http.post(this._baseUrl + 'Visit/SaveReservationGuided', reservationGuidedForm).subscribe((response) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
      });

      if (response['codeThird'] === "0000") {

        Swal.fire({
          title: response['msg'],
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this.formReservationGuided.reset();
          Object.keys(this.formReservationGuided.controls).forEach(key => {
            this.formReservationGuided.get(key).setErrors(null);
          });
        });

        //Toast.fire({
        //  icon: 'success',
        //  title: response['msg'],
        //  timer: 5000,
        //  showConfirmButton: false
        //});
      }
      else if (response['codeThird'] === "9999") {
        Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        });
      }
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      //el.scrollIntoView({ behavior: 'smooth' });
      //var elementPosition = el.getBoundingClientRect().top;
      //var offsetPosition = elementPosition + window.pageYOffset - 140;

      //window.scrollTo({ top: offsetPosition, behavior: 'smooth' });

      document.getElementById(el.id).focus();
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }
}
