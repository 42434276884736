import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-memorabilia',
  templateUrl: './memorabilia.component.html',
  styleUrls: ['./memorabilia.component.css', '../../../assets/CustomizationMofia/about/timeline.css']
})
export class MemorabiliaComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  private _activatedRoute: ActivatedRoute;
  memorabilia: any[];
  tablinkVal: number;
  private _title: Title;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("about");
    this.navSel.emit("/Memorabilia");
    this.tablinkVal = 1;

    const filter = {
      type: 1,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'About/InitMemorabilia', filter).subscribe((response) => {
      //console.log(response);
      this.memorabilia = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 纖博大事紀");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Events");
    }
  }

  changeTabLinks(val: number) {
    this.tablinkVal = val;

    const filter = {
      type: val,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'About/InitMemorabilia', filter).subscribe((response) => {
      this.memorabilia = response['data'];
    });
  }
}
