import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.css']
})
export class BusinessHoursComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public businessHours: string = "";

  public Lang: string;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;

  }

  ngOnInit() {
    this.closeNav.emit("visit");
    this.navSel.emit("/BusinessHours");

    this.Lang = localStorage.getItem('Language');

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 開放時間");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Opening Hours");
    }

    var filter = {
      type: 1,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Visit/SearchVisit', filter).subscribe((response) => {
      this.businessHours = response['data'][0].html;
    });
  }

}
