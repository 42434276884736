import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { IndexComponent } from './index/index.component'; //首頁

import { NgxPaginationModule } from 'ngx-pagination';

import { SlickCarouselModule } from 'ngx-slick-carousel'; //輪播

import { DatePipe } from '@angular/common'; //日期

import { MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';

import { FullCalendarModule } from '@fullcalendar/angular'; //日曆套件

import { NgxLinkifyjsModule } from 'ngx-linkifyjs'; //轉換連結網址

//最新公告
import { BulletinListComponent } from './news-bulletin/bulletin-list/bulletin-list.component'; //最新公告清單
import { BulletinComponent } from './news-bulletin/bulletin/bulletin.component'; //最新公告內容

//參觀模組
import { BusinessHoursComponent } from './visit/business-hours/business-hours.component';//開放時間
import { VisitInstructionsComponent } from './visit/visit-instructions/visit-instructions.component'; //參觀須知
import { ReservationGuidedComponent } from './visit/reservation-guided/reservation-guided.component'; //預約導覽
import { TrafficInfoComponent } from './visit/traffic-info/traffic-info.component'; //交通資訊

//我要報名
import { WorkshopMemberLoginComponent } from './registration/workshop-member-login/workshop-member-login.component'; //研習學員登入
import { WorkshopMemberComponent } from './registration/workshop-member/workshop-member.component'; //會員基本資料
import { WorkshopMemberClassComponent } from './registration/workshop-member-class/workshop-member-class.component'; //會員課程
import { WorkshopMemberForgetComponent } from './registration/workshop-member-forget/workshop-member-forget.component'; //忘記密碼
import { CommonQuestionComponent } from './registration/common-question/common-question.component'; //常見問題
import { ActivityWorkshopMainComponent } from './registration/activity-workshop-main/activity-workshop-main.component'; //活動研習主要畫面
import { ActivityListComponent } from './registration/activity-list/activity-list.component'; //活動列表
import { ActivityComponent } from './registration/activity/activity.component'; //活動內容
import { LectureListComponent } from './registration/lecture-list/lecture-list.component'; //講座列表
import { LectureComponent } from './registration/lecture/lecture.component'; //講座內容
import { WorkshopListComponent } from './registration/workshop-list/workshop-list.component'; //研習列表
import { WorkshopComponent } from './registration/workshop/workshop.component'; //研習內容
import { WorkshopAgreeComponent } from './registration/workshop-agree/workshop-agree.component'; //研習同意書
import { WorkshopRegistrationComponent } from './registration/workshop-registration/workshop-registration.component'; //研習報名註冊
import { ActivityAgreeComponent } from './registration/activity-agree/activity-agree.component'; //活動同意書
import { LectureAgreeComponent } from './registration/lecture-agree/lecture-agree.component'; //講座同意書
import { ActivityLectureRegistrationComponent } from './registration/activity-lecture-registration/activity-lecture-registration.component';

//典藏研究
import { CollectionListComponent } from './collection-research/collection-list/collection-list.component'; //典藏精選清單
import { CollectionComponent } from './collection-research/collection/collection.component'; //典藏精選內容
import { CollectionPolicyComponent } from './collection-research/collection-policy/collection-policy.component'; //點藏政策
import { ResearchListComponent } from './collection-research/research-list/research-list.component'; //研究列表
import { ResearchComponent } from './collection-research/research/research.component'; //研究內容

//志工
import { ManagementRegulationsComponent } from './volunteer/management-regulations/management-regulations.component'; //志工隊管理規定
import { ActivityHighlightsComponent } from './volunteer/activity-highlights/activity-highlights.component'; //活動花絮
import { RecruitmentMethodComponent } from './volunteer/recruitment-method/recruitment-method.component'; //招募辦法

//展覽
import { ExhibitMainComponent } from './exhibit/exhibit-main/exhibit-main.component'; //展覽主頁
import { ExhibitComponent } from './exhibit/exhibit/exhibit.component'; //展覽

//關於纖博
import { CompanyVisionComponent } from './about/company-vision/company-vision.component'; //發展願景
import { OrganizationComponent } from './about/organization/organization.component'; //組織架構
import { SpaceTourComponent } from './about/space-tour/space-tour.component'; //空間導覽
import { MemorabiliaComponent } from './about/memorabilia/memorabilia.component'; //纖博大世紀
import { DirectorIntroductionComponent } from './about/director-introduction/director-introduction.component';//認識主任

//纖維創作獎
import { AboutCreationComponent } from './creation/about-creation/about-creation.component'; //關於纖維創作獎
import { OnlineRegistrationComponent } from './creation/online-registration/online-registration.component'; //線上報名
import { NewsListComponent } from './creation/news-list/news-list.component'; //最新消息列表
import { NewsComponent } from './creation/news/news.component'; //最新消息

import { RewardWorkListComponent } from './creation/reward-work-list/reward-work-list.component'; //得獎作品列表
import { RewardWorkComponent } from './creation/reward-work/reward-work.component'; //得獎作品內容

//出版文創
import { PublishMainComponent } from './publish/publish-main/publish-main.component'; //出版文創列表
import { PublishComponent } from './publish/publish/publish.component'; //出版文創內容

//便民服務
import { ContactComponent } from './convenience/contact/contact.component';//聯絡我們
import { GovInfoListComponent } from './convenience/gov-info-list/gov-info-list.component'; //政府資訊公開
import { RelatedLinkListComponent } from './convenience/related-link-list/related-link-list.component';//相關連結
import { DownloaderListComponent } from './convenience/downloader-list/downloader-list.component';//下載專區
import { BorrowSpaceComponent } from './convenience/borrow-space/borrow-space.component';//空間租借
import { SitemapComponent } from './sitemap/sitemap.component';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    IndexComponent,
    BulletinListComponent,
    BulletinComponent,
    BusinessHoursComponent,
    VisitInstructionsComponent,
    ReservationGuidedComponent,
    TrafficInfoComponent,
    WorkshopMemberComponent,
    WorkshopMemberLoginComponent,
    WorkshopMemberClassComponent,
    WorkshopMemberForgetComponent,
    CommonQuestionComponent,
    CollectionListComponent,
    CollectionComponent,
    CollectionPolicyComponent,
    ManagementRegulationsComponent,
    ActivityHighlightsComponent,
    RecruitmentMethodComponent,
    ExhibitMainComponent,
    CompanyVisionComponent,
    OrganizationComponent,
    SpaceTourComponent,
    AboutCreationComponent,
    OnlineRegistrationComponent,
    ResearchListComponent,
    ResearchComponent,
    PublishMainComponent,
    PublishComponent,
    NewsListComponent,
    NewsComponent,
    RewardWorkListComponent,
    RewardWorkComponent,
    ContactComponent,
    ActivityWorkshopMainComponent,
    GovInfoListComponent,
    ActivityComponent,
    LectureListComponent,
    ActivityListComponent,
    WorkshopListComponent,
    WorkshopComponent,
    LectureComponent,
    RelatedLinkListComponent,
    ExhibitComponent,
    DownloaderListComponent,
    MemorabiliaComponent,
    BorrowSpaceComponent,
    WorkshopAgreeComponent,
    WorkshopRegistrationComponent,
    ActivityAgreeComponent,
    LectureAgreeComponent,
    ActivityLectureRegistrationComponent,
    DirectorIntroductionComponent,
    SitemapComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SlickCarouselModule,
    FullCalendarModule,
    MatFormFieldModule, MatInputModule, MatDatepickerModule, MatNativeDateModule,
    BrowserAnimationsModule,
    NgxLinkifyjsModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: IndexComponent, pathMatch: 'full' },
      { path: 'Index', component: IndexComponent },
      { path: 'BulletinList', component: BulletinListComponent },
      { path: 'BulletinList/:id', component: BulletinComponent },
      { path: 'BusinessHours', component: BusinessHoursComponent },
      { path: 'VisitInstructions', component: VisitInstructionsComponent },
      { path: 'ReservationGuided', component: ReservationGuidedComponent },
      { path: 'TrafficInfo', component: TrafficInfoComponent },
      { path: 'WorkshopMember/Login', component: WorkshopMemberLoginComponent },
      { path: 'WorkshopMember/Info', component: WorkshopMemberComponent, canActivate: [AuthGuard] },
      { path: 'WorkshopMember/Class', component: WorkshopMemberClassComponent, canActivate: [AuthGuard] },
      { path: 'WorkshopMember/Forget', component: WorkshopMemberForgetComponent },
      { path: 'WorkshopMember/CommonQuestion', component: CommonQuestionComponent },
      { path: 'CollectionList', component: CollectionListComponent },
      { path: 'CollectionList/:id', component: CollectionComponent },
      { path: 'ResearchList', component: ResearchListComponent },
      { path: 'ResearchList/:id', component: ResearchComponent },
      { path: 'ManagementRegulations', component: ManagementRegulationsComponent },
      { path: 'ActivityHighlights', component: ActivityHighlightsComponent },
      { path: 'RecruitmentMethod', component: RecruitmentMethodComponent },
      { path: 'ExhibitMain/:type', component: ExhibitMainComponent },
      { path: 'CompanyVision', component: CompanyVisionComponent },
      { path: 'Organization', component: OrganizationComponent },
      { path: 'SpaceTour', component: SpaceTourComponent },
      { path: 'AboutCreation', component: AboutCreationComponent },
      { path: 'OnlineRegistration', component: OnlineRegistrationComponent },
      { path: 'CollectionPolicy', component: CollectionPolicyComponent },
      { path: 'PublishMain/:type', component: PublishMainComponent },
      { path: 'Publish/:id', component: PublishComponent },
      { path: 'NewsList', component: NewsListComponent },
      { path: 'NewsList/:id', component: NewsComponent },
      { path: 'RewardWorkList', component: RewardWorkListComponent },
      { path: 'RewardWorkList/:id', component: RewardWorkComponent },
      { path: 'Contact', component: ContactComponent },
      { path: 'GovInfoList', component: GovInfoListComponent },
      { path: 'ActivityWorkshopMain', component: ActivityWorkshopMainComponent },
      { path: 'WorkshopList/:id', component: WorkshopComponent },
      { path: 'WorkshopList/:id/WorkshopAgree', component: WorkshopAgreeComponent },
      { path: 'WorkshopList/:id/WorkshopRegistration', component: WorkshopRegistrationComponent },
      { path: 'ActivityList/:id', component: ActivityComponent },
      { path: 'ActivityList/:id/ActivityAgree', component: ActivityAgreeComponent },
      { path: 'ActivityList/:id/ActivityRegistration', component: ActivityLectureRegistrationComponent },
      { path: 'LectureList/:id', component: LectureComponent },
      { path: 'LectureList/:id/LectureAgree', component: LectureAgreeComponent },
      { path: 'LectureList/:id/LectureRegistration', component: ActivityLectureRegistrationComponent },
      { path: 'RelatedLinkList', component: RelatedLinkListComponent },
      { path: 'Exhibit/:id', component: ExhibitComponent },
      { path: 'DownloaderList', component: DownloaderListComponent },
      { path: 'Memorabilia', component: MemorabiliaComponent },
      { path: 'BorrowSpace', component: BorrowSpaceComponent },
      { path: 'DirectorIntroduction', component: DirectorIntroductionComponent },
      { path: 'Sitemap', component: SitemapComponent }
    ])
  ],
  providers: [
    DatePipe,
    { provide: 'BASE_URL', useFactory: getBaseUrl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}
