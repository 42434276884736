import { Component, OnInit, Output, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { IPublish } from '@model/publish';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  public typeTitle: string = "";

  private _title: Title;

  @ViewChild('publishDiv1', { static: true }) simple1;
  @ViewChild('publishDiv2', { static: true }) simple2;

  public publish: IPublish = {
    id: 0,
    name: "",
    type: 0,
    html: "",
    author: "",
    publishUnit: "",
    publishDate: "",
    isbn: "",
    price: 0,
    img: "",
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;

    this.publish.id = Number(activatedRoute.snapshot.paramMap.get('id'));
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("");
    const getEnglishId = {
      type: 4,
      Id: this.publish.id,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Exhibit/getEnglishExhibitId', getEnglishId).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        if (response['data'].isChangeLang) {
          if (response['data'].id !== 0) {
            this.publish.id = response['data'].id;
            this._router.navigate(['/Publish/' + response['data'].id]);
          }

          this._http.post(this._baseUrl + 'Publish/SearchPublish', this.publish.id).subscribe((response) => {

            if (response['codeThird'] === "0000") {
              this.publish.id = response['data'].id;
              this.publish.name = response['data'].name;
              this.publish.type = response['data'].type;
              this.publish.html = response['data'].html;
              this.publish.author = response['data'].author;
              this.publish.publishUnit = response['data'].publishUnit;
              this.publish.publishDate = response['data'].publishDate;
              this.publish.isbn = response['data'].isbn;
              this.publish.price = response['data'].price;
              this.publish.img = response['data'].img;
              this.simple1.nativeElement.innerHTML = response['data'].html;
              this.simple2.nativeElement.innerHTML = response['data'].html;

              if (this.publish.type == 1) {
                this.typeTitle = this.Lang === '1' ? "出版品" : 'Publications';
                this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 出版品" : 'Museum Of Fiber Arts - Publications');
              }
              else if (this.publish.type == 2) {
                this.typeTitle = this.Lang === '1' ? "文創商品" : 'Cultural Creations';
                this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 文創商品" : 'Museum Of Fiber Arts - Cultural Creations');
              }
              else if (this.publish.type == 3) {
                this.typeTitle = this.Lang === '1' ? "圖像授權" : 'Image Licensing';
                this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 圖像授權" : 'Museum Of Fiber Arts - Image Licensing');
              }
            }
            else {
              Swal.fire({
                title: response['msg'],
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
              }).then((result) => {
                if (this.publish.type == 1) {
                  this._router.navigate(['/PublishMain/Publish']);
                }
                else if (this.publish.type == 2) {
                  this._router.navigate(['/PublishMain/Cultural']);
                }
                else if (this.publish.type == 3) {
                  this._router.navigate(['/PublishMain/Authoriza']);
                }
              });
            }
          });
        }
        else {
          this._router.navigate(['/PublishMain/Publish']);
        }
      }
      else {
        Swal.fire({
          title: "系統發生錯誤請聯絡纖博",
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/PublishMain/Publish']);
        });
      }
    });
    
  }

}
