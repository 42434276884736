import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ViewEncapsulation, Pipe } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IRelatedLink, IGovInfoList } from '@model/relatedlink';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-gov-info-list',
  //styles: ['.demo {background-color: blue}'],
  //template: '<div [innerHTML]="someHtmlCode"></div>',
  templateUrl: './gov-info-list.component.html',
  styleUrls: ['./gov-info-list.component.css',
              '../../../assets/CustomizationMofia/creation/css/creation.css',
  ],
  encapsulation: ViewEncapsulation.None
  //'../../../assets/CustomizationMofia/commons/page/css/am-pagination.css'
})
export class GovInfoListComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  p: number;
  public Lang: string;
  public govInfoList: IGovInfoList[];
  private _title: Title;
  private _sanitizer: DomSanitizer;

  private someHtmlCode = '';

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, public datepipe: DatePipe, private titleService: Title, private sanitizer: DomSanitizer) {
    this._http = http;
    this._baseUrl = baseUrl;
    this._title = titleService;
    this._sanitizer = sanitizer;

    this.someHtmlCode = '<div class="demo"><b>This is my HTML.</b></div>';
  }

  filter: IRelatedLink = {
    name: "",
    type: 1,
    lang: Number(localStorage.getItem('Language'))
  }


  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("convenience");
    this.navSel.emit("/GovInfoList");

    this._http.post(this._baseUrl + 'RelatedLink/SearchRelatedLink', this.filter).subscribe((response) => {
      
      this.p = 1;
      if (response['data'].length > 0) {
        response['data'][0].name = this._sanitizer.bypassSecurityTrustHtml(response['data'][0].name);
        this.govInfoList = response['data'];
      }
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 政府資訊公開");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Open Government Declaration");
    }
  }

  clickup(id: number) {

    this._http.post(this._baseUrl + 'RelatedLink/SaveRelatedLink', id).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        this.govInfoList = response['data'];
      }
      this._http.post(this._baseUrl + 'RelatedLink/SearchRelatedLink', this.filter).subscribe((response) => {
        this.govInfoList = response['data'];
      });
    });
    

  }
}
