import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { IDownloadLink } from '@model/download';
import { from } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-downloader-list',
  templateUrl: './downloader-list.component.html',
  styleUrls: ['./downloader-list.component.css', '../../../assets/CustomizationMofia/commons/page/css/am-pagination.css']
})
export class DownloaderListComponent implements OnInit {
  downloadList: any[];
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  p: number;
  public Lang: string;
  public search: string;
  private _title: Title;

  filter: IDownloadLink = {
    name: "",
    lang: Number(localStorage.getItem('Language'))
  }

  private _baseUrl: string;
  private _http: HttpClient;
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("convenience");
    this.navSel.emit("/DownloaderList");


    this._http.post(this._baseUrl + 'Downloader/SearchDownload', this.filter).subscribe((response) => {

      this.p = 1;
      this.downloadList = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 下載專區");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Download");
    }
  }

  SearchDownloadByFilter(form: any)
  {
    this.filter = {
      name: form.txtFilterName,
      lang: Number(localStorage.getItem('Language'))
    }
    this._http.post(this._baseUrl + 'Downloader/SearchDownload', this.filter).subscribe((response) => {

      this.p = 1;
      this.downloadList = response['data'];
    });
      
    

  }
}
