import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators,FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css', '../../../assets/CustomizationMofia/commons/page/css/form.css' ]
})
export class ContactComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _ContactImportant: string;
  public formContact: FormGroup;
  public radioe = false;
  public Lang: string;
  public contact = {
    name: "",
    sex:"",
    createTime: "",
    email: "",
    phone: "",
    status: "",
    description: "",
    tel:""
  };
  private judgeEmail: boolean; //電子信箱是否合法
  private judgeContactPhone: boolean; //手機號碼是否合法
  private _title: Title;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }
  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("convenience");
    this.navSel.emit("/Contact");
    this.formContact = this.fb.group({
      name: [null, [Validators.required]],
      sex: [''],
      createTime: [''],
      email: [null, [Validators.required, Validators.pattern(new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"))]],
      phone: [null, [Validators.pattern(new RegExp("09[0-9]{8}"))]],
      status: [''],
      description: [null, [Validators.required]],
      tel: [null]
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 連絡我們");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Contact");
    }
  }
  
  public check()
  {
    if ((this.contact.phone != null && this.contact.phone != "") || (this.contact.tel != null && this.contact.tel !="")) {
      this.radioe = true;
    }
  }

  //驗證信箱格式
  public blurEmail() {
    var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (pattern.test(this.contact.email)) {
      this.judgeEmail = true;
    }
    else {
      this.judgeEmail = false;
    }
  }

  SaveContact() {
    //
    const contactForm = this.contact;

    if (!this.formContact.valid || !this.radioe) {
      this.scrollToError();
      return;
    }
    this._http.post(this._baseUrl + 'Convenience/SaveContact', contactForm).subscribe((response) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
      });

      if (response['codeThird'] === "0000") {
        this.formContact.reset();
        this.radioe = false;
        Toast.fire({
          icon: 'success',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        });
      }
      else if (response['codeThird'] === "9999") {
        Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        });
      }
    });
    
  }

  scrollTo(el: Element): void {
    if (el) {
      //el.scrollIntoView({ behavior: 'smooth' });
      //var elementPosition = el.getBoundingClientRect().top;
      //var offsetPosition = elementPosition + window.pageYOffset - 140;

      //window.scrollTo({ top: offsetPosition, behavior: 'smooth' });

      document.getElementById(el.id).focus();
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}
