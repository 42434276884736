import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IPostWorkshopMemberLogin } from '@model/workshop';
import { encryptByEnAES, decryptByDeAES } from '@model/share';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop-member-login',
  templateUrl: './workshop-member-login.component.html',
  styleUrls: [
    './workshop-member-login.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/login.css',
    '../../../assets/CustomizationMofia/register/css/register.css'
  ]
})
export class WorkshopMemberLoginComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public _captchaUrl: string;
  public _captchaAlt: string;
  private _ErrorMsg: string;
  public isShowError: boolean = false;
  public isLogin: boolean;
  public captcha: string;
  public idNumbe: string;
  //public password: string;
  public _hide: boolean = true;
  private _title: Title;
  @ViewChild('paw', { static: true }) paw: ElementRef;

  public postWorkshopMemberLogin: IPostWorkshopMemberLogin = {
    account: "",
    password: "",
    captcha: ""
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._captchaUrl = "";
    this._captchaAlt = "";
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("registration");
    this.navSel.emit("/WorkshopMember/Login");
    this._title.setTitle("臺中市纖維工藝博物館 - 學員專區");

    if (sessionStorage.getItem('WorkshopMemberId') != null) {
      if (decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')) != "") {
        //已登入直接跳轉
        this._router.navigate(['/WorkshopMember/Info']);
      }
    }

    this._http.get(this._baseUrl + 'Workshop/Captcha').subscribe(
      (response) => {
        this._captchaUrl = response['img'];

        this._captchaAlt  = decryptByDeAES((response['text']).toString());
        //this._captchaAlt = response['text'];
      });
  }

  //重新產生數字圖形驗證碼
  resetCaptcha(): void {
    this._http.get(this._baseUrl + 'Workshop/Captcha').subscribe(
      (response) => {
        this._captchaUrl = response['img'];

        this._captchaAlt = decryptByDeAES((response['text']).toString());
      });
  }

  //清除資料
  clear(): void {
    this.isLogin = false;
    this.resetCaptcha();
    this.clearMessage();
    this.isShowError = false;
  }

  //清除錯誤訊息
  clearMessage(): void {
    this._ErrorMsg = "";
    this.isShowError = false;
  }

  //錯誤訊息顯示
  showMessage(message: string): void {
    this._ErrorMsg = message;
    this.isShowError = true;
  }

  //登入按鈕
  Login() {
    if (this.idNumbe !== "" && this.paw.nativeElement.value !== "" && this.captcha !== "") {
      this.clearMessage();

      this.postWorkshopMemberLogin = {
        account: this.idNumbe,
        password: this.paw.nativeElement.value,
        captcha: this.captcha
      }

      this._http.post(this._baseUrl + 'Workshop/isLogin', this.postWorkshopMemberLogin).subscribe(
        (response) => {
          if (response['codeThird'] === "0000") {
            this.resetCaptcha();
            this._router.navigate(['/WorkshopMember/Info']);
            sessionStorage.setItem('WorkshopMemberId', encryptByEnAES((response["data"]).toString()));
          }
          else {
            this.showMessage(response["msg"]);
            this.resetCaptcha();
          }
        });
    }
    else {
      this.showMessage("帳密以及驗證碼不可為空");
    }
  }

  //是否顯示明碼密碼欄位
  OpenPwd() {
    this._hide = !this._hide;
  }

  AlertLogin() {
    Swal.fire({
      title: '請先登入學員',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonText: '確認',
      allowOutsideClick: false
    });
  }

  //驗證碼語音服務
  async videoCaptcha() {
    const chars = this._captchaAlt.split('');
    const audio1 = new Audio();
    audio1.src = "../../assets/numMp3/" + chars[0] + ".mp3";
    audio1.play();
    audio1.addEventListener('ended', function () {
      const audio2 = new Audio();
      audio2.src = "../../assets/numMp3/" + chars[1] + ".mp3";
      audio2.play();

      audio2.addEventListener('ended', function () {
        const audio3 = new Audio();
        audio3.src = "../../assets/numMp3/" + chars[2] + ".mp3";
        audio3.play();

        audio3.addEventListener('ended', function () {
          const audio4 = new Audio();
          audio4.src = "../../assets/numMp3/" + chars[3] + ".mp3";
          audio4.play();
        }, false);
      }, false);
    }, false);
  }

}
