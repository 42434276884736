import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IClassStatus, IActivity } from '@model/activity-lecture';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;

  private _title: Title;

  public IsCanRegistration: boolean = true;
  public IsFull: boolean = false;

  public classStatusList: IClassStatus[];

  public activity: IActivity = {
    id: 0,
    name: "", //名稱
    session: "", //場次
    date: "", //日期
    venue: "", //地點
    quota: 0, //名額
    banner: "", //圖片
    fee: 0, //費用
    feeDeadline: "", //繳費期限
    feeRemark: "", //費用備註
    introduction: "", //內容介紹
    prospectiveStudent: "", //招生對象
    minCapacity: 0, //開班人數
    alternate: 0, //候補人數
    remainingAlternate: 0, //剩餘候補人數
    numberOfRegistration: 0, //報名人數
    numberOfPaid: 0,
    remainingNumber: 0, //剩餘人數
    classStatus: 0, //開班狀態
    organizer: "臺中市纖維工藝博物館", //主辦單位
    contactInformation: "", //聯絡方式
    remark: "" //備註
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, public datepipe: DatePipe, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this.activity.id = Number(activatedRoute.snapshot.paramMap.get('id'));

    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");
    this.isLangDisplay.emit(true);

    this._http.post(this._baseUrl + 'ActivityLecture/GetClassStatus', null).subscribe((response) => {
      this.classStatusList = response['data'];
    });

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");

    this._http.post(this._baseUrl + "ActivityLecture/SearchActivity", this.activity.id).subscribe((response) => {
      if (response['codeThird'] == "0000") {
        this.activity.id = response['data'].id;
        this.activity.name = response['data'].name;
        this.activity.session = response['data'].session;
        this.activity.date = this.datepipe.transform(response['data'].date, "yyyy/MM/dd HH:mm:ss");
        this.activity.venue = response['data'].venue;
        this.activity.quota = response['data'].quota;
        this.activity.banner = response['data'].banner;
        this.activity.fee = response['data'].fee;
        this.activity.feeDeadline = this.datepipe.transform(response['data'].feeDeadline, "yyyy-MM-dd");
        this.activity.feeRemark = response['data'].feeRemark;
        this.activity.introduction = response['data'].introduction;
        this.activity.prospectiveStudent = response['data'].prospectiveStudent;
        this.activity.minCapacity = response['data'].minCapacity;
        this.activity.alternate = response['data'].alternate;
        if (response['data'].numberOfRegistration >= response['data'].quota) {
          this.activity.remainingAlternate = response['data'].quota + response['data'].alternate - response['data'].numberOfRegistration;
        }
        this.activity.numberOfRegistration = response['data'].numberOfRegistration;
        this.activity.numberOfPaid = response['data'].numberOfPaid;
        this.activity.classStatus = response['data'].classStatus;
        this.activity.remainingNumber = response['data'].remainingNumber;
        this.activity.organizer = response['data'].organizer;
        this.activity.contactInformation = response['data'].contactInformation;
        this.activity.remark = response['data'].remark;

        //比較時間可否報名
        var now = new Date();
        var activityDate = new Date(this.activity.date);
        if (now > activityDate) {
          this.IsCanRegistration = false;
        }

        //判斷報名人數
        if ((this.activity.quota + this.activity.alternate) <= this.activity.numberOfRegistration) {
          this.IsFull = true;
        }
      }
      else {
        Swal.fire({
          title: response['msg'],
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/ActivityWorkshopMain']);
        });
      }
    });

  }

  GetClassStatusText(key: number) {
    var text: string = "";

    if (key != 0) {
      text = this.classStatusList.find(x => x.key == key).text;
    }

    return text;
  }

}
