import { Component, OnInit, ViewChild, ElementRef, Inject, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { HostListener } from '@angular/core';
import { decryptByDeAES } from "@model/share";
import { HttpClient } from "@angular/common/http";
import { DOCUMENT } from "@angular/common";


@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.css"]
})
export class NavMenuComponent implements OnInit {
  public isOpenNav: boolean = false;
  public isOpenNavLevel2: boolean = false;
  public type: string = "";
  public subType: string = "";
  public level: string = "";
  public router: string = "";
  public double_nav_type: string = "";
  public Language: number;

  public showSearch: boolean = false;
  public showText: string = "臺中市纖維工藝博物館";

  public IsLogin: boolean = false;  //判斷是否登入我要報名

  public isLangDisplay: boolean = true;

  private _baseUrl: string;
  private _http: HttpClient;

  public IsMActivity: boolean = false;  //是否有纖維創作獎報名活動

  public logoImg: any;

  public customTabIndex: number = 2; //自訂tabindex，為了無障礙

  @ViewChild("hcMobileNav", { static: false }) hcMobileNav: ElementRef;
  @ViewChild('menu', { static: false }) menuElement: ElementRef;
  @ViewChild('go_to_center_block', { static: false }) centerElement: ElementRef;
  @ViewChild('nav_panel', { static: false }) navPanel: ElementRef;
  navSel: any;

  //沒再使用了 kai20240318
  //@HostListener('window:keyup', ['$event'])
  //keyEvent(event: KeyboardEvent) {
  //  //if (event.altKey && event.keyCode === 85) {
  //  //  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  //  //}

  //  //if (event.keyCode === 9 ) {
  //  //  //console.log(this.document.activeElement);

  //  //}

  //  if (event.altKey && (event.keyCode === 67 || event.code == "KeyC")) {
  //    //console.log("alt + c has been click");

  //  }
  //  //if (event.altKey && event.keyCode === 66) {
  //  //  window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
  //  //}

  //  if (event.keyCode === 27) { //esc
  //    if (this.isOpenNav) {
  //      this.isOpenNav = false;
  //      this.menuElement.nativeElement.focus();
  //    }
  //  }
  //}

  constructor(private elementRef: ElementRef, private _router: Router, http: HttpClient, @Inject('BASE_URL') baseUrl: string, @Inject(DOCUMENT) private document: Document) {
    this.router = _router.url;
    this._http = http;
    this._baseUrl = baseUrl;
  }
  ngOnInit() {
    if (localStorage.getItem('Language') !== null) {
      if (localStorage.getItem('Language') === "1") {
        //中文
        this.Language = 1;
        this.document.documentElement.lang = 'zh-Hant';
      }
      else if (localStorage.getItem('Language') === "2") {
        //英文
        this.Language = 2;
        this.document.documentElement.lang = 'en';
      }
    }
    else {
      //中文
      this.Language = 1;
      localStorage.setItem('Language', "1");
      this.document.documentElement.lang = 'zh-Hant';
    }

    //判斷是否有纖維創作獎線上報名活動
    this._http.get(this._baseUrl + 'Creation/InitClassActivity').subscribe((response) => {
      //console.log(response['data']);
      if (response['data'] !== null) {
        this.IsMActivity = true;
      }
      else {
        this.IsMActivity = false;
      }
    });
  }

  changeLanguage(newLanguage: number) {
    var split_arr = window.location.href.split('?');

    if (newLanguage != null) {
      this.Language = newLanguage;
      localStorage.setItem('Language', newLanguage.toString());
      if (this.Language == 1) {
        this.document.documentElement.lang = 'zh-Hant';
        window.location.href = split_arr[0];
      }
      else {
        this.document.documentElement.lang = 'en';
        window.location.href = split_arr[0] + "?lang=EN";
      }
    }
    else {
      this.Language = 1;
      localStorage.setItem('Language', newLanguage.toString());
      this.document.documentElement.lang = 'zh-Hant';
      window.location.href = split_arr[0];
    }

    //location.reload();
  }

  //開啟/關閉漢堡選單
  TogglerNav() {
    this.isOpenNav = !this.isOpenNav;
    if (this.isOpenNav) { //開啟
    }
    else { //關閉
      document.getElementById("lang-ch").focus();
    }
  }


  OpenNav() {
    this.hcMobileNav.nativeElement.style.display = "block";
    this.isOpenNav = true;
  }

  CloseNav() {
    this.level = "";
    this.type = "";
    this.isOpenNav = false;
  }

  //監聽鍵盤事件
  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    var targetElement = event.srcElement;
    if (event.key === 'Enter') { //當按下Enter
      if (targetElement["id"] == "toggler-nav") { //漢堡選單被觸發
        if (!this.isOpenNav) { //如果是要準備開啟的，跳到第一個導覽元素
          setTimeout(() => {
            var firstNavItem = this.document.getElementById("first_nav_item") as HTMLElement;
            firstNavItem.focus();
          }, 100)
        }
      }
    }

    if (event.key === 'Tab' && !event.shiftKey) { //當按下tab
      if (targetElement["id"] == "last-nav-item") { //當最後一個導覽元素要離開的時候，關閉漢堡並且將focus移至語系
        this.isOpenNav = !this.isOpenNav;
        setTimeout(() => {
          var langCH = this.document.getElementById("lang-ch") as HTMLElement;
          langCH.focus();
        }, 100)
      }
      //if (event.srcElement['className'].includes("tablinks")) {

      //}
    }
    else if (event.key === 'Tab' && event.shiftKey) { //當按下tab + shift
    }

    if (event.key === 'Escape') {
      if (this.isOpenNav) {
        this.isOpenNav = false;
        this.menuElement.nativeElement.focus();
      }
    }
  }

  OpenNavLevel2(type: string = "") {
    this.level = "level2";
    if (this.type != type && this.isOpenNavLevel2) {
      //如果與當前 type 不同就無需關閉
      this.isOpenNavLevel2 = true;
    }
    else {
      this.isOpenNavLevel2 = !this.isOpenNavLevel2;
    }
    this.type = type;
  }

  CloseNavLevel2() {
    this.level = "level1";
    this.type = "";
  }

  OpenNavLevel3(subType: string = "") {
    this.level = "level3";
    this.subType = subType;
  }

  CloseNavLevel3() {
    this.level = "level2";
    this.subType = "";
  }

  CloseNavLevel2Filter($event: any) {
    let classList = $event.target.classList;
    if (classList.contains("sub-level-open")) {
      this.CloseNavLevel3();
      this.CloseNavLevel2();
    }
  }

  CloseNavLevel3Filter($event: any) {
    let classList = $event.target.classList;
    if (classList.contains("sub-level-open")) {
      this.CloseNavLevel3();
    }
  }

  onChange(value) {
    var split_arr = value.split('?');

    if (split_arr[0] === '/MofiaVolunteer') {
      window.open('/MofiaVolunteer', '_blank');
    }
    else {
      if (split_arr.length > 1) {
        this._router.navigate([split_arr[0], { lang: 'EN' }]);
      } else {
        this._router.navigate([split_arr[0]]);
      }
    }

    this.navSel = value;
  }

  onActivate(componentReference) {
    window.scrollTo(0, 0);
    document.getElementById('first').focus();
    document.getElementById('first').blur();


    if (componentReference.closeNav !== null && componentReference.navSel !== undefined) {
      componentReference.closeNav.subscribe((data) => { //關閉nav
        this.level = "";
        this.type = "";
        this.isOpenNav = false;
        this.double_nav_type = data;
      });
    }

    if (componentReference.isLangDisplay !== null && componentReference.isLangDisplay !== undefined) {
      //有傳值 就不顯示
      this.isLangDisplay = false;
    }
    else {
      this.isLangDisplay = true;
    }


    if (componentReference.navSel !== null && componentReference.navSel !== undefined) {
      componentReference.navSel.subscribe((data) => { //手機板下拉式選單
        this.navSel = data;
      });
    }

    if (sessionStorage.getItem('WorkshopMemberId') != null) {
      if (decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')) != "") {
        this.IsLogin = true;
      }
    }

    ////判斷是否有纖維創作獎線上報名活動
    //this._http.get(this._baseUrl + 'Creation/InitClassActivity').subscribe((response) => {
    //  if (response['data'] !== null) {
    //    this.IsMActivity = true;
    //  }
    //  else {
    //    this.IsMActivity = false;
    //  }
    //});
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    //console.log('Back button pressed');

    //console.log(this.router);

    if (this.router === "/") {
      //this.CloseNav();
      //this.double_nav_type = "";
      location.reload();
    }
  }

  searchToggle() {
    //var container = $(obj).closest('.search-wrapper');

    //if (!container.hasClass('active')) {
    //  container.addClass('active');
    //  //evt.preventDefault();
    //}
    //else if (container.hasClass('active') && $(obj).closest('.input-holder').length == 0) {
    //  container.removeClass('active');
    //  // clear input
    //  container.find('.search-input').val('');
    //  // clear and hide result container when we press close
    //  container.find('.result-container').fadeOut(100, function () { $(this).empty(); });
    //}

    if (this.showSearch) {
      window.open("https://www.google.com/search?sxsrf=ALeKk03L_tSOqpsxCNtH9eh5f2KpRe5Pjw%3A1601263769802&ei=mVhxX6OrMLTomAX-6b7oDg&q=" + this.showText + "&oq=" + this.showText + "&gs_lcp=CgZwc3ktYWIQAzICCAAyAggAMgIIADICCAAyBAgAEB4yBAgAEB4yBAgAEB4yBAgAEB4yBAgAEB4yBAgAEB5Q2hBY2hBg6RJoAHAAeACAATWIATWSAQExmAEAoAECoAEBqgEHZ3dzLXdpesABAQ&sclient=psy-ab&ved=0ahUKEwjj28Kx9IrsAhU0NKYKHf60D-0Q4dUDCA0&uact=5", "_blank")
    }
    else {
      this.showSearch = !this.showSearch;
    }
  }

  gotoContent(event: Event) {
    //window.scroll({ top: (document.body.scrollHeight * 3 / 100), left: 0, behavior: 'smooth' });

    //let keyboardEvent = new KeyboardEvent('keydown', {
    //  altKey: true,
    //  code: "Digit0",
    //});

    //console.log(keyboardEvent);

    //window.dispatchEvent(keyboardEvent);

    document.getElementById('content-point').focus();

  }

}
