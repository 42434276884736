import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IDir } from '../../model/director-introduction';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-director-introduction',
  templateUrl: './director-introduction.component.html',
  styleUrls: ['./director-introduction.component.css']
})
export class DirectorIntroductionComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  public Lang: string;
  private _baseUrl: string;
  private _http: HttpClient;
  dirLeg: string;
  dirEdu: string;
  dirExp: string;
  dirImg: string;
  filter: IDir = {
    lang: Number(localStorage.getItem('Language'))
  }
  @ViewChild('EducationDiv', { static: true }) education;
  @ViewChild('ExperienceDiv', { static: true }) experience;
  private _title: Title;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("about");
    this.navSel.emit("/GovInfoList");
    this._http.post(this._baseUrl + 'About/DirectorIntroduction', this.filter).subscribe((response) => {
      this.dirImg = response['data'].imgName;
      this.dirLeg = response['data'].legend;
      this.education.nativeElement.innerHTML = response['data'].education;
      this.experience.nativeElement.innerHTML = response['data'].experience;
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 認識主任");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Meet The Director");
    }
  }

}
