import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { IWorkshop, IClassStatus, ISpecialStatus } from '@model/workshop';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.css']
})
export class WorkshopComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public IsCanRegistration: boolean = true;
  public isOpenSignup: boolean = false;
  public IsFull: boolean = false;

  public classStatusList: IClassStatus[];
  public specialStatusList: ISpecialStatus[];

  public workshop: IWorkshop = {
    id: 0,
    name: "", //名稱
    session: "", //場次
    startDate: "", //起始日期
    endDate: "", //結束日期
    openSignup: "", //開放報名日期
    venue: "", //地點
    quota: 0, //名額
    banner: "", //圖片
    fee: 0, //費用
    feeDeadline: "", //繳費期限
    feeRemark: "", //費用備註
    totalHours: 0, //總時數
    classNumber: 0, //課堂數
    classIntro: "", //課程介紹
    instructorName: "", //講師姓名
    instructorInfo: "", //講師介紹
    briefIntro: "", //內容簡介
    registrationNote: "", //報名須知
    prospectiveStudent: "", //招生對象
    specialStatus: 0, //特殊身份
    certifiedDocument: 0, //證明文件
    minCapacity: 0, //開班人數
    alternate: 0, //候補人數
    remainingAlternate: 0, //剩餘候補人數
    numberOfRegistration: 0, //報名人數
    numberOfPaid: 0, //已付費人數
    remainingNumber: 0, //剩餘人數
    classStatus: 0, //開班狀態
    organizer: "", //主辦單位
    contactInformation: "", //聯絡方式
    annexFileList: [], //附件名稱
    remark: "" //備註
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, public datepipe: DatePipe, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this.workshop.id = Number(activatedRoute.snapshot.paramMap.get('id'));

    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");

    //取得開班狀態欄位
    this._http.post(this._baseUrl + 'Workshop/GetClassStatus', null).subscribe((response) => {
      this.classStatusList = response['data'];
    });

    //取得特殊身份
    this._http.post(this._baseUrl + 'Workshop/GetSpecialStatus', null).subscribe((response) => {
      this.specialStatusList = response['data'];
    });

    this._http.post(this._baseUrl + 'Workshop/SearchWorkshop', this.workshop.id).subscribe((response) => {
      console.log(response);
      if (response['codeThird'] === "0000") {
        this.workshop.id = response['data'].id;
        this.workshop.name = response['data'].name;
        this.workshop.session = response['data'].session;
        this.workshop.startDate = this.datepipe.transform(response['data'].startDate, "yyyy/MM/dd");
        this.workshop.endDate = this.datepipe.transform(response['data'].endDate, "yyyy/MM/dd");
        this.workshop.openSignup = this.datepipe.transform(response['data'].openSignup, "yyyy/MM/dd");
        this.workshop.venue = response['data'].venue;
        this.workshop.quota = response['data'].quota;
        this.workshop.banner = response['data'].banner;
        this.workshop.fee = response['data'].fee;
        this.workshop.feeDeadline = this.datepipe.transform(response['data'].feeDeadline, "yyyy-MM-dd");
        this.workshop.feeRemark = response['data'].feeRemark;
        this.workshop.totalHours = response['data'].totalHours;
        this.workshop.classNumber = response['data'].classNumber;
        this.workshop.classIntro = response['data'].classIntro;
        this.workshop.instructorName = response['data'].instructorName;
        this.workshop.instructorInfo = response['data'].instructorInfo;
        this.workshop.briefIntro = response['data'].briefIntro;
        this.workshop.registrationNote = response['data'].registrationNote;
        this.workshop.prospectiveStudent = response['data'].prospectiveStudent;
        this.workshop.specialStatus = response['data'].specialStatus;
        this.workshop.certifiedDocument = response['data'].certifiedDocument;

        this.workshop.minCapacity = response['data'].minCapacity;
        this.workshop.alternate = response['data'].alternate;
        if (response['data'].numberOfRegistration >= response['data'].quota) {
          this.workshop.remainingAlternate = response['data'].quota + response['data'].alternate - response['data'].numberOfRegistration;
        }
        this.workshop.numberOfRegistration = response['data'].numberOfRegistration;
        this.workshop.numberOfPaid = response['data'].numberOfPaid;


        this.workshop.classStatus = response['data'].classStatus;
        //this.workshop.remainingNumber = response['data'].remainingNumber;
        this.workshop.organizer = response['data'].organizer;
        this.workshop.contactInformation = response['data'].contactInformation;
        this.workshop.annexFileList = response['data'].annexFileList;
        this.workshop.remark = response['data'].remark;

        var now = new Date();
        //比較時間可否開放報名
        if (this.workshop.openSignup != null && this.workshop.openSignup != "") {
          var openSignup = new Date(this.workshop.openSignup);
          if (now >= openSignup) {
            this.isOpenSignup = true;
          }
        }

        //比較時間可否報名已截止
        var workshopDate = new Date(this.workshop.startDate);
        if (now > workshopDate) {
          this.IsCanRegistration = false;
        }

        //判斷報名人數
        if ((this.workshop.quota + this.workshop.alternate) <= this.workshop.numberOfRegistration) {
          this.IsFull = true;
        }
       
      }
      else {
        Swal.fire({
          title: response['msg'],
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/ActivityWorkshopMain']);
        });
      }
    });

    
  }

  GetClassStatusText(key: number) {
    var text: string = "";

    if (key != 0) {
      text = this.classStatusList.find(x => x.key == key).text;
    }

    return text;
  }

  GetSpecialStatusText(key: number) {
    var text: string = "";
    if (key != 0) {
      var selectedSpecial = this.specialStatusList.filter(x => (x.key & key) > 0);
      selectedSpecial.forEach((x, index) => {
        if (index == 0) {
          text += x.text;
        }
        else {
          text += "、" + x.text;
        }
      });
    }
    else {
      text = "無";
    }
    return text;
  }

}
