import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ISpecialStatus, IWorkshop } from '@model/workshop';
import { Router, ActivatedRoute } from '@angular/router';
import { decryptByDeAES, encryptByEnAES } from '@model/share';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop-registration',
  templateUrl: './workshop-registration.component.html',
  styleUrls: [
    './workshop-registration.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/form.css'
  ]
})
export class WorkshopRegistrationComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public specialStatusList: ISpecialStatus[] = [];

  public workshopId: number = 0;

  public formWorkshop: FormGroup;

  selectedSpecialStatusList = [];

  public workshop: IWorkshop = {
    id: 0,
    name: "", //名稱
    session: "", //場次
    startDate: "", //起始日期
    endDate: "", //結束日期
    openSignup: "", //開放報名日期
    venue: "", //地點
    quota: 0, //名額
    banner: "", //圖片
    fee: 0, //費用
    feeDeadline: "", //繳費期限
    feeRemark: "", //費用備註
    totalHours: 0, //總時數
    classNumber: 0, //課堂數
    classIntro: "", //課程介紹
    instructorName: "", //講師姓名
    instructorInfo: "", //講師介紹
    briefIntro: "", //內容簡介
    registrationNote: "", //報名須知
    prospectiveStudent: "", //招生對象
    specialStatus: 0, //特殊身份
    certifiedDocument: 0, //證明文件
    minCapacity: 0, //開班人數
    alternate: 0, //候補人數
    remainingAlternate: 0, //剩餘候補人數
    numberOfRegistration: 0, //報名人數
    numberOfPaid: 0, //已付費人數
    remainingNumber: 0, //剩餘人數
    classStatus: 0, //開班狀態
    organizer: "", //主辦單位
    contactInformation: "", //聯絡方式
    annexFileList: [], //附件名稱
    remark: "" //備註
  }

  public workshopRegistration = {
    idNumber: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    specialStatus: 0,
    annex: null,
    workshopId: 0
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, public fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;

    this.workshopId = Number(activatedRoute.snapshot.paramMap.get('id'));
    this.workshopRegistration.workshopId = this.workshopId;

    this.formWorkshop = this.fb.group({
      name: [null, Validators.required],
      idNumber: [null, [Validators.required, Validators.pattern(new RegExp("[a-zA-Z][0-9]{9}"))]],
      //address: [null, Validators.required],
      phone: [null, [Validators.required, Validators.pattern(new RegExp("(^0\\d{1,2}-?(\\d{6,8})$)|(^09[0-9]{8}$)"))]],
      email: [null, [Validators.required, Validators.pattern(new RegExp("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,64}"))]],
    });
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");
    this.isLangDisplay.emit(true);
    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");

    //取得該研習內容
    this._http.post(this._baseUrl + 'Workshop/SearchWorkshop', this.workshopId).subscribe((response) => {
      this.workshop = response['data'];

      var now = new Date();
      //比較時間是否已開放報名
      if (this.workshop.openSignup != null && this.workshop.openSignup != "") {
        var openSignup = new Date(this.workshop.openSignup);
        if (now < openSignup) {
          this._router.navigate(['/WorkshopList/' + this.workshopId]);
        }
      }

      //比較時間是否報名已截止
      var workshopDate = new Date(this.workshop.startDate);
      if (now > workshopDate) {
        this._router.navigate(['/WorkshopList/' + this.workshopId]);
      }
      if ((this.workshop.quota + this.workshop.alternate) <= this.workshop.numberOfPaid) {
        this._router.navigate(['/WorkshopList/' + this.workshopId]);
      }

    });

    //取得特殊身份
    this._http.post(this._baseUrl + 'Workshop/GetSpecialStatus', null).subscribe((response) => {
      response['data'].forEach(x => {
        if ((this.workshop.specialStatus & x.key) > 0) {
          this.specialStatusList.push(x);
        }
      });
    });

    if (sessionStorage.getItem('WorkshopMemberId') != null) {
      if (decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')) != "") {
        //已登入直接抓會員資料
        this._http.post(this._baseUrl + 'Workshop/SearchWorkshopMember', Number(decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')))).subscribe((response) => {

          if (response['codeThird'] === "0000") {
            this.workshopRegistration.idNumber = response['data'].idNumber;
            this.workshopRegistration.name = response['data'].name;
            this.workshopRegistration.phone = response['data'].phone;
            this.workshopRegistration.email = response['data'].email;
          }
          //else {
          //  Swal.fire({
          //    title: response['msg'],
          //    icon: 'error',
          //    showCancelButton: false,
          //    confirmButtonText: '確認',
          //    allowOutsideClick: false,
          //    preConfirm: function () {
          //      sessionStorage.removeItem("WorkshopMemberId");
          //      this._router.navigate(['/WorkshopMember/Login']);
          //    }
          //  });
          //}
        });
      }
    }

  }

  SaveWorkshop() {
    let formData = new FormData();
    formData.append("workshopId", this.workshopRegistration.workshopId.toString());
    formData.append("name", this.workshopRegistration.name);
    formData.append("idNumber", this.workshopRegistration.idNumber);
    formData.append("address", this.workshopRegistration.address);
    formData.append("phone", this.workshopRegistration.phone);
    formData.append("email", this.workshopRegistration.email);
    formData.append("annex", this.workshopRegistration.annex);
    formData.append("specialStatus", this.FetchSumSpecialStatusValue().toString());

    if (!this.formWorkshop.valid || !this.verificationNumID()) {
      return;
    }

    this._http.post(this._baseUrl + 'Workshop/SaveWorkshopRegistration', formData).subscribe((response) => {

      if (response['codeThird'] == "0000") {
        Swal.fire({
          title: response['msg'],
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/WorkshopMember/Info']);
          sessionStorage.setItem('WorkshopMemberId', encryptByEnAES((response["data"]).toString()));
        });
      }
      else if (response['codeThird'] == "9999") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        }).then((result) => {
          this._router.navigate(['/WorkshopList/' + this.workshopId]);
        });
      }
      else {
        this.Toast.fire({
          title: response['msg'],
          icon: 'warning',
          timer: 5000,
          showConfirmButton: false
        }).then((result) => {
          this._router.navigate(['/WorkshopList/' + this.workshopId]);
        });
      }
    });
  }

  FetchSumSpecialStatusValue() {
    var sum = 0;
    this.selectedSpecialStatusList.forEach(x => sum += x.key);
    return sum;
  }

  //邊更特殊身分
  ChangeSpecialStatus(key: number, isChecked: boolean) {
    var temp = this.specialStatusList.find(x => x.key == key);

    if (isChecked) {
      temp.isChecked = true;
      this.selectedSpecialStatusList.push(temp);
    }
    else {
      let index = this.selectedSpecialStatusList.findIndex(x => x.key == key);
      this.selectedSpecialStatusList.splice(index, 1);
    }
  }

  //選擇附件
  SelectedAnnexOnChanged(event) {
    
    if (event.target.files && event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].size > 3 * 1024 * 1024) {
          this.Toast.fire({
            icon: 'error',
            title: "超過上傳限制，請重新選擇",
            timer: 5000
          });

          return;
        }
      }

      this.workshopRegistration.annex = <File>event.target.files[0];
    }
  }

  //SetSelectedSpecialStatus() {
  //  this.specialStatusList.forEach(x => {
  //    if (Number(this.workshop.specialStatus & x.key) > 0) {
  //      x.isChecked = true;
  //      this.selectedSpecialStatusList.push(x);
  //    }
  //    else {
  //      x.isChecked = false;
  //      let index = this.selectedSpecialStatusList.findIndex(y => y.key == x.key);
  //      this.selectedSpecialStatusList.splice(index, 1);
  //    }
  //  });
  //}

  //身分證輸入時
  verificationNumID() {
    return this.checkTwID(this.workshopRegistration.idNumber);
  }

  //驗證身分證格式
  checkTwID(id) {
    //建立字母分數陣列(A~Z)
    const city = new Array(
      1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11,
      20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30
    )
    id = id.toUpperCase();


    var pattern = /^[A-Z]\d{9}$/;

    //使用「正規表達式」檢驗格式
    if (pattern.test(this.workshopRegistration.idNumber)) {
      //將字串分割為陣列(IE必需這麼做才不會出錯)
      id = id.split('');
      //計算總分
      var total = city[id[0].charCodeAt(0) - 65];
      for (var i = 1; i <= 8; i++) {
        total += eval(id[i]) * (9 - i);
      }
      //補上檢查碼(最後一碼)
      total += eval(id[9]);
      //檢查比對碼(餘數應為0);
      return ((total % 10 === 0));
    }
    else {
      return false;
    }
  }

}
