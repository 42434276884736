import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-online-registration',
  templateUrl: './online-registration.component.html',
  styleUrls: ['./online-registration.component.css', '../../../assets/CustomizationMofia/commons/page/css/form.css']
})
export class OnlineRegistrationComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public aboutCreation: string = "";
  public _isDis1: boolean; //是否隱藏步驟一
  public _isDis2: boolean; //是否隱藏步驟二
  public _isDis3: boolean; //是否隱藏步驟三
  public _registrationTitle: string;  //步驟別
  public _class: string;  //屆別
  public _classId: number;  //屆別流水號

  public formOnlineStep1: FormGroup;  //步驟一From表
  public authorImgPreview: string;  //作者照片src
  public fileauthorImage: File = null; //作者照片圖檔

  public iDNumberImagePreviews = [];  //身分證src
  public fileIDNumberImage: File[] = []; //身分證圖檔

  public formOnlineStep2: FormGroup;  //步驟二From表
  public designImagePreviews = [];  //設計圖稿src
  public fileDesignImage: File[] = []; //設計圖稿圖檔
  public worksImagePreviews = [];  //作品照src
  public fileWorksImage: File[] = []; //作品照圖檔

  public formOnlineStep3: FormGroup;  //步驟三From表

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  //步驟一資料
  public OnlineStep1 = {
    participateType: "1", //參賽類別
    author: "",  //作者姓名/代表人姓名(中文)
    eAuthor: "",  //作者姓名/代表人姓名(英文)
    title: "",  //作品名稱(中文)
    eTitle: "", //作品名稱(英文)
    iDNumber: "", //身分證字號
    isAboriginal: "0", //是否式原住民
    aboriginal: "",  //原住民是哪一族
    address: "",  //地址
    eMail: "",  //電子信箱
    tel: "",  //電話
    phone: "",  //手機
    fax: "",  //傳真
    authorResume: "" //作者簡歷
  };

  //步驟二資料
  public OnlineStep2 = {
    finishYear: "", //作品完成(年)
    finishMonth: "", //作品完成(月)
    insuranceAmount: 0, //作品保險價格
    specification: "",  //規格
    material: "", //材質
    technique: "",  //技法
    creativeIdea: ""  //創作理念
  };

  //步驟三資料
  public OnlineStep3 = {
    cParticipant: "", //參與人員(中文)
    eParticipant: "", //參與人員(英文)
    cParticipant1: "", //參與人員1(中文)
    eParticipant1: "", //參與人員1(英文)
    cParticipant2: "", //參與人員2(中文)
    eParticipant2: "", //參與人員2(英文)
    cParticipant3: "", //參與人員3(中文)
    eParticipant3: "", //參與人員3(英文)
    cParticipant4: "", //參與人員4(中文)
    eParticipant4: "", //參與人員4(英文)
    cParticipant5: "", //參與人員5(中文)
    eParticipant5: "", //參與人員5(英文)
  };

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this.authorImgPreview = "";
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("creation");
    this.navSel.emit("/OnlineRegistration");

    this._isDis1 = false;
    this._isDis2 = true;
    this._isDis3 = true;
    this._registrationTitle = "一";

    this._title.setTitle("臺中市纖維工藝博物館 - 線上報名");
   


    //抓取屆別順便判斷是否可以線上報名
    this._http.get(this._baseUrl + 'Creation/InitClassActivity').subscribe((response) => {
      //console.log(response['data']);
      if (response['data'] !== null) {
        this._classId = response['data'].id;
        this._class = response['data'].class;
      }
      else {
        this._router.navigate(["/NewsList"]);
      }
    });

    this.formOnlineStep1 = this.fb.group({
      raoParticipateType: ['1'],
      author: [null, [Validators.required]],
      eAuthor: [null, [Validators.required]],
      title: [null, [Validators.required]],
      eTitle: [null, [Validators.required]],
      iDNumber: [null, [Validators.required]],
      raoIsAboriginal: ['0'],
      aboriginal: [''],
      address: [null, [Validators.required]],
      eMail: [null, [Validators.required]],
      tel: [''],
      phone: [''],
      fax: [''],
      authorResume: [null, [Validators.required]]
    });

    this.formOnlineStep2 = this.fb.group({
      finishYear: [null, [Validators.required, Validators.min(1990), Validators.max(2050)]],
      finishMonth: [null, [Validators.required, Validators.min(1), Validators.max(12)]],
      insuranceAmount: [0, [Validators.required, Validators.min(0), Validators.max(200000)]],
      specification: [null, [Validators.required]],
      material: [null, [Validators.required]],
      technique: [null, [Validators.required]],
      creativeIdea: [null, [Validators.required]],
    });

    this.formOnlineStep3 = this.fb.group({
      cParticipant: [null, [Validators.required]],
      eParticipant: [null, [Validators.required]],
      cParticipant1: [null],
      eParticipant1: [null],
      cParticipant2: [null],
      eParticipant2: [null],
      cParticipant3: [null],
      eParticipant3: [null],
      cParticipant4: [null],
      eParticipant4: [null],
      cParticipant5: [null],
      eParticipant5: [null],
    });
  }

  //步驟判斷
  step(val: number) {
    window.scrollTo(0, 0);
    if (val === 1) {
      this._isDis1 = false;
      this._isDis2 = true;
      this._isDis3 = true;
      this._registrationTitle = "一";
    }
    else if (val === 2) {
      this._isDis1 = true;
      this._isDis2 = false;
      this._isDis3 = true;
      this._registrationTitle = "二";
    }
    else {
      this._isDis1 = true;
      this._isDis2 = true;
      this._isDis3 = false;
      this._registrationTitle = "三";
    }
  }

  //選擇作者照片
  onSelectAuthorImage(event) {
    if (event.target.files && event.target.files[0]) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false
      });


      if (event.target.files[0].size > 3 * 1024 * 1024) {
        Toast.fire({
          icon: 'error',
          title: "超過上傳限制，請重新選擇",
          timer: 5000
        });

        return;
      }
      this.fileauthorImage = <File>event.target.files[0];

      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.authorImgPreview = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  //刪除作者照片
  DeleteAuthor() {
    this.authorImgPreview = "";
    this.fileauthorImage = null;
  }

  //選擇多張身分證照片上傳
  onSelectIDNumberImage(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].size > 3 * 1024 * 1024) {
          this.Toast.fire({
            icon: 'error',
            title: "超過上傳限制，請重新選擇",
            timer: 5000
          });

          return;
        }
      }

      for (let i = 0; i < filesAmount; i++) {
        this.fileIDNumberImage.push(event.target.files[i]);

        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.iDNumberImagePreviews.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  //刪除身分證圖片
  DeleteIDNumber(index: number) {
    this.fileIDNumberImage.splice(index, 1);
    this.iDNumberImagePreviews.splice(index, 1);
  }

  //選擇設計圖稿照片
  onSelectDesignImage(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].size > 3 * 1024 * 1024) {
          this.Toast.fire({
            icon: 'error',
            title: "超過上傳限制，請重新選擇",
            timer: 5000
          });

          return;
        }
      }

      for (let i = 0; i < filesAmount; i++) {
        this.fileDesignImage.push(event.target.files[i]);

        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.designImagePreviews.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  //刪除設計圖稿照片
  DeleteDesign(index: number) {
    this.fileDesignImage.splice(index, 1);
    this.designImagePreviews.splice(index, 1);
  }

  //選擇作品照照片
  onSelectWorksImage(event) {
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;

      for (let i = 0; i < filesAmount; i++) {
        if (event.target.files[i].size > 3 * 1024 * 1024) {
          this.Toast.fire({
            icon: 'error',
            title: "超過上傳檔案大小3M限制，請重新選擇",
            timer: 5000
          });

          return;
        }
      }

      for (let i = 0; i < filesAmount; i++) {
        this.fileWorksImage.push(event.target.files[i]);

        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.worksImagePreviews.push(event.target.result);
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  //刪除作品照照片
  DeleteWorks(index: number) {
    this.fileWorksImage.splice(index, 1);
    this.worksImagePreviews.splice(index, 1);
  }

  //儲存線上報名
  SaveOnlineRefistration() {
    //Form1
    var formData = new FormData();
    formData.append('id', this._classId.toString()); //屆別流水號
    formData.append('participateType', this.OnlineStep1.participateType); //參賽類別
    formData.append('author', this.OnlineStep1.author); //作者姓名
    formData.append('eAuthor', this.OnlineStep1.eAuthor); //作者姓名(英文)
    formData.append('title', this.OnlineStep1.title); //作品名稱
    formData.append('eTitle', this.OnlineStep1.eTitle); //作品名稱(英文)
    formData.append('iDNumber', this.OnlineStep1.iDNumber); //身分證字號
    formData.append('isAboriginal', this.OnlineStep1.isAboriginal); //是否是原住民
    formData.append('aboriginal', this.OnlineStep1.aboriginal); //原住民
    formData.append('address', this.OnlineStep1.address); //地址
    formData.append('eMail', this.OnlineStep1.eMail); //信箱
    formData.append('tel', this.OnlineStep1.tel); //電話
    formData.append('phone', this.OnlineStep1.phone); //手機
    formData.append('fax', this.OnlineStep1.fax); //傳真
    formData.append('authorResume', this.OnlineStep1.authorResume); //作者簡歷

    //Form2
    formData.append('finishYear', this.OnlineStep2.finishYear); //作品完成(年)
    formData.append('finishMonth', this.OnlineStep2.finishMonth); //作品完成(月)
    formData.append('insuranceAmount', this.OnlineStep2.insuranceAmount.toString()); //作品保險金額
    formData.append('specification', this.OnlineStep2.specification); //規格
    formData.append('material', this.OnlineStep2.material); //材質
    formData.append('technique', this.OnlineStep2.technique); //技法
    formData.append('creativeIdea', this.OnlineStep2.creativeIdea); //創作理念

    //圖檔
    formData.append("fileauthorImage", this.fileauthorImage); //作者照片
    this.fileIDNumberImage.forEach(x => {
      formData.append("fileIDNumberImage", x);  //身分證
    });
    this.fileDesignImage.forEach(x => {
      formData.append("fileDesignImage", x);  //設計圖稿
    });
    this.fileWorksImage.forEach(x => {
      formData.append("fileWorksImage", x);  //作品照
    });

    //Form3
    formData.append('cParticipant', this.OnlineStep3.cParticipant); //參與人員(中文)
    formData.append('eParticipant', this.OnlineStep3.eParticipant); //參與人員(英文)
    formData.append('cParticipant1', this.OnlineStep3.cParticipant1); //參與人員1(中文)
    formData.append('eParticipant1', this.OnlineStep3.eParticipant1); //參與人員1(英文)
    formData.append('cParticipant2', this.OnlineStep3.cParticipant2); //參與人員2(中文)
    formData.append('eParticipant2', this.OnlineStep3.eParticipant2); //參與人員2(英文)
    formData.append('cParticipant3', this.OnlineStep3.cParticipant3); //參與人員3(中文)
    formData.append('eParticipant3', this.OnlineStep3.eParticipant3); //參與人員3(英文)
    formData.append('cParticipant4', this.OnlineStep3.cParticipant4); //參與人員4(中文)
    formData.append('eParticipant4', this.OnlineStep3.eParticipant4); //參與人員4(英文)
    formData.append('cParticipant5', this.OnlineStep3.cParticipant5); //參與人員5(中文)
    formData.append('eParticipant5', this.OnlineStep3.eParticipant5); //參與人員5(英文)

    this._http.post(this._baseUrl + 'Creation/SaveOnlineRegistration', formData).subscribe((response) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
      });

      if (response['codeThird'] === "0000") {
        Swal.fire({
          title: response['msg'],
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false
        }).then((result) => {
          this.formOnlineStep1.reset();
          this.formOnlineStep2.reset();
          this.formOnlineStep3.reset();

          this.authorImgPreview = "";  //作者照片src
          this.fileauthorImage = null; //作者照片圖檔

          this.iDNumberImagePreviews = [];  //身分證src
          this.fileIDNumberImage = []; //身分證圖檔

          this.designImagePreviews = [];  //設計圖稿src
          this.fileDesignImage = []; //設計圖稿圖檔
          this.worksImagePreviews = [];  //作品照src
          this.fileWorksImage = []; //作品照圖檔

          this.step(1);
        });
      }
      else if (response['codeThird'] === "9999") {
        Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        });
      }
    });
  }
}
