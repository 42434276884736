import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-traffic-info',
  templateUrl: './traffic-info.component.html',
  styleUrls: ['./traffic-info.component.css']
})
export class TrafficInfoComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public detailNos: any[];
  public trafficInfo: string;
  public hrefLink: string;

  public Lang: string;
  private _title: Title;

  public VisitImg: string = "";

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;

  }

  ngOnInit() {
    this.closeNav.emit("visit");
    this.navSel.emit("/TrafficInfo");

    this.Lang = localStorage.getItem('Language');

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 交通資訊");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Traffic Information");
    }

    this._http.post(this._baseUrl + 'Visit/GetDetailNo', null).subscribe((response) => {
      this.detailNos = response['data'];
    });

    const filter = {
      type: 3,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Visit/SearchVisit', filter).subscribe((response) => {
      this.trafficInfo = response['data'];
    });

    const link = {
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Visit/SearchVisitLink', link).subscribe((response) => {
      this.hrefLink = response['data'];
    });

    this._http.post(this._baseUrl + 'Visit/InitVisitImg', link).subscribe((response) => {
      this.VisitImg = response['data'];
    });
  }

  GetDetailNoText(key: number) {
    let text: string = "";
    if (key != 99) {
      text = this.detailNos.find(x => x.key === key).text;
    }

    return text;
  }
}
