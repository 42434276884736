import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, HostListener, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: [
    './index.component.css'
  ]
})
export class IndexComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>();
  public isOpenNav: boolean = false;
  public type: string = "";
  public level: string = "";
  public double_nav_type: string = "";
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _activatedRoute: ActivatedRoute;

  private _title: Title;

  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal2', { static: true }) slickModal2: SlickCarouselComponent;
  @ViewChild('slickModal3', { static: true }) slickModal3: SlickCarouselComponent; //手機板banner

  slideConfig = {
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    accessibility: false,
    nextArrow: '<button class="slick-next banner-slick-next" aria-label="Next" type="button">Next</button>',
    prevArrow: '<button class="slick-prev banner-slick-prev" aria-label="Previous" type="button">Previous</button>',
  };

  slideConfig2 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    accessibility: false,
    nextArrow: '<button class="slick-next banner-slick-next" aria-label="Next" type="button">Next</button>',
    prevArrow: '<button class="slick-prev banner-slick-prev" aria-label="Previous" type="button">Previous</button>',
    infinite: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public homepageBannerList: any[];

  @ViewChild('businessHoursDiv', { static: true }) simple;

  @ViewChild('tablinks', { static: false }) tablinks;
  @ViewChild('contentDiv', { static: true }) contentDiv;

  public Lang: string;
  public homepagequicklink: any[];
  public homepageExhibitList: any[];
  public firstExhibit = {
    id: 0,
    name: "",
    mainIcon: "",
  };
  public newsList: any[];
  public collectionRegistrationList: any[];
  public homepageDialogList: any[];

  tablinkVal: number;
  public currentTablinkIndex: number = 0;
  public BulletinList: any[];
  p: number = 1;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("");

    this.level = "";
    this.type = "";
    this.isOpenNav = false;
    this.tablinkVal = 0;

    //取得banner
    this._http.post(this._baseUrl + 'Homepage/SearchHomepageBanner', null).subscribe((response) => {
      console.log("HomepageBanner", response);
      if (response['codeThird'] === "0000") {
        this.homepageBannerList = response['data'];
      }
    });
    //取得快速連結
    this._http.get(this._baseUrl + 'Homepage/LinkList').subscribe((response) => {
      console.log("LinkList");
      if (response['codeThird'] === "0000") {
        this.homepagequicklink = response['data'];
      }
    });

    //取得展覽
    var exhibitFilter = {
      lang: Number(localStorage.getItem('Language'))
    }
    this._http.post(this._baseUrl + 'Homepage/SearchHomepageExhibit', exhibitFilter).subscribe((response) => {

      if (response['codeThird'] === "0000") {
        this.homepageExhibitList = response['data'];
        this.firstExhibit = this.homepageExhibitList[0];
      }
    });

    //取得開放時間
    this.Lang = localStorage.getItem('Language');

    var businessHoursFilter = {
      type: 1,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Visit/SearchVisit', businessHoursFilter).subscribe((response) => {
      this.simple.nativeElement.innerHTML = response['data'][0].html;
    });

    ////取得最新消息
    //var newsFilter = {
    //  lang: Number(localStorage.getItem('Language'))
    //}

    //this._http.post(this._baseUrl + 'Homepage/SearchHomepageNews', newsFilter).subscribe((response) => {
    //  if (response['codeThird'] === "0000") {
    //    this.newsList = response['data'];
    //  }
    //});

    var filter = {
      type: this.tablinkVal,
      lang: Number(localStorage.getItem('Language'))
    }

    //取得最新公告
    this._http.post(this._baseUrl + 'Bulletin/SearchBulletinByFilter', filter).subscribe((response) => {
      this.BulletinList = response['data'];
    });

    //取得典藏 / 課程介紹
    var homepageCollectionFilter = {
      lang: Number(localStorage.getItem('Language'))
    }
    this._http.post(this._baseUrl + 'Homepage/SearchHomepageCollection', homepageCollectionFilter).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        this.collectionRegistrationList = response['data'];
      }
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts");
    }

    this._http.get(this._baseUrl + 'Homepage/SearchHomepageDialog').subscribe((response) => {
      if (response['codeThird'] === "0000") {
        this.homepageDialogList = response['data'];
        //console.log(response);
        if (response['data']['showable'] == "1") {
          document.getElementById("myModal").style.display = "block";
        }
      }
    });
  }

  closeModal() {
    document.getElementById("myModal").style.display = "none";
  }


  //最新消息 - 增加點擊次數
  //AddPoint(id: number) {
  //  this._http.post(this._baseUrl + 'News/AddPoint', id).subscribe((response) => {
  //  });
  //}

  AddPoint(id: number) {
    this._http.post(this._baseUrl + 'Bulletin/AddPoint', id).subscribe((response) => {
    });
  }

  @HostListener('keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    console.log(event);
    var activeElement = document.activeElement as HTMLElement;
    console.log("activeElement", activeElement);
    if (event.key === 'Tab' && !event.shiftKey) { // 當按下tab

      //更改 最下方的幻燈片
      const slickItems = document.querySelectorAll('.slick-slide') as NodeListOf<HTMLElement>;
      slickItems.forEach((item, index) => {
        item.setAttribute('aria-hidden', 'false');
      });


      if (activeElement.className.includes("tablinks")) {
        event.preventDefault(); // 阻止預設的鍵盤事件
        this.currentTablinkIndex = parseInt(event.srcElement['id'].split("-")[event.srcElement['id'].split("-").length - 1]);

        var link_a = this.contentDiv.nativeElement.querySelector('a') as HTMLElement;
        if (link_a != null) {
          link_a.focus(); // 將焦點設置到下方資訊
        }
        else { //沒有下方的資訊。就跳轉到下一個
          document.getElementById("tab-link-" + (this.currentTablinkIndex + 1)).focus();
        }
      }
      else if (event.srcElement['innerText'] == 'page') {
        if (this.currentTablinkIndex == 4) {
        }
        else {
          console.log("page之後的currentTablinkIndex", this.currentTablinkIndex);
          console.log(this.tablinks.nativeElement.querySelectorAll('button'));
          this.tablinks.nativeElement.querySelectorAll('button')[this.currentTablinkIndex].focus();
        }
      }
    }
    else if (event.key === 'Tab' && event.shiftKey) { //當按下tab + shift
    }

    setTimeout(() => {
      console.log("wait 100ms document.activeElement", document.activeElement);
      var activeElement = document.activeElement as HTMLElement;
      if (activeElement.className.includes("tablinks")) {
        activeElement.click();
      }
    }, 100)


  }

  changeTabLinks(val: number) {
    console.log(val);
    this.tablinkVal = val;

    const filter = {
      type: val,
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'Bulletin/SearchBulletinByFilter', filter).subscribe((response) => {
      this.BulletinList = response['data'];
    });
  }

  slickAfterChange(e) {
    const slickItems = document.querySelectorAll('.slick-slide') as NodeListOf<HTMLElement>;
    slickItems.forEach((item, index) => {
        item.setAttribute('aria-hidden', 'false');
    });
  }
}
