import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop-agree',
  templateUrl: './workshop-agree.component.html',
  styleUrls: [
    './workshop-agree.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/form.css'
  ]
})
export class WorkshopAgreeComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public workshopId: number = 0;
  public workshopName: string = "";
  public IsCheck: boolean = false;

  constructor(private _router: Router, activatedRoute: ActivatedRoute, http: HttpClient, @Inject('BASE_URL') baseUrl: string, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;

    this.workshopId = Number(activatedRoute.snapshot.paramMap.get('id'));

    if (activatedRoute.queryParams != null) {
      activatedRoute.queryParams.subscribe((queryParams) => {
        this.workshopName = queryParams['workshopName'];
      });
    }
  }

  ngOnInit() {
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");
  }

}
