import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import listPlugin from '@fullcalendar/list'; // for dateClick
import { EventInput } from '@fullcalendar/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-borrow-space',
  templateUrl: './borrow-space.component.html',
  styleUrls: ['./borrow-space.component.css']
})
export class BorrowSpaceComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _activatedRoute: ActivatedRoute;
  private _title: Title;

  @ViewChild('calendar', { static: false }) calendarComponent: FullCalendarComponent;
  private calendarApi
  @ViewChild('RemoveEvents', { static: false }) elemRef;

  //日曆套件
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin, listPlugin];
  //日曆事件
  calendarEvents: EventInput[] = [];

  //日曆Header轉換繁體中文
  calendarButtonText = {
    today: '今天', month: '月', week: '週', day: '天', list: '活動列表'
  };

  //日曆Header擺放位置
  calendarHeader = {
    left: 'prev',
    center: 'title',
    right: 'dayGridMonth, next' //dayGridMonth timeGridDay listWeek
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("convenience");
    this.navSel.emit("/BorrowSpace");

    this._title.setTitle("臺中市纖維工藝博物館 - 空間租借");

    //const filter = {
    //  type: 1,
    //  lang: localStorage.getItem('Language')
    //}

    //this._http.post(this._baseUrl + 'Volunteer/InitAbout', filter).subscribe((response) => {
    //  this.companyVision = response['data'].html;
    //});
  }

  datesRender() {
    this.calendarApi = this.calendarComponent.getApi();
    let currentStartDate = this.calendarApi.view.currentStart;
    let currentEndDate = this.calendarApi.view.currentEnd;

    //this.calendarEvents =
    //  [{
    //    title: '08:00AM~05:00PM 凱翔出差日', start: "2020-10-15", end: "2020-10-17 12:00:00.000", color: '#5b8221', allDay: false},
    //  { title: '08:00AM~11:00AM 凱翔相親日', start: "2020-10-20", end: "2020-10-22", color: '#5b8221', allDay: true }
    //  ];

    let initScheduleList = {
      searchSDate: new Date(currentStartDate).toISOString().split('T')[0],
      searchEDate: new Date(currentEndDate).toISOString().split('T')[0]
    };

    this._http.post(this._baseUrl + "Convenience/InitBorrowSpace", initScheduleList).subscribe((response) => {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false
      });

      if (response['codeThird'] === "0000") {
        this.calendarEvents = response['data'];
      }
      else if (response['codeThird'] === "9999") {
        Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        });
      }
    });
  }
}
