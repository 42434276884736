import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-creation',
  templateUrl: './about-creation.component.html',
  styleUrls: ['./about-creation.component.css']
})
export class AboutCreationComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  private _title: Title;

  public aboutCreation: string = "";
  public AboutImg: string = "";

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("creation");
    this.navSel.emit("/AboutCreation");

    const filter = {
      type: 4,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Volunteer/InitAbout', filter).subscribe((response) => {
      this.aboutCreation = response['data'].html;
    });

    this._http.post(this._baseUrl + 'About/InitAboutCreationImg', filter).subscribe((response) => {
      this.AboutImg = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 關於纖維創作獎");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - About");
    }
  }
}
