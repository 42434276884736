import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IRewardWorkFilter } from '../../model/reward-work';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reward-work-list',
  templateUrl: './reward-work-list.component.html',
  styleUrls: [
    './reward-work-list.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/form.css',
    '../../../assets/CustomizationMofia/creation/css/creation.css',
    '../../../assets/CustomizationMofia/commons/page/css/am-pagination.css'
  ]
})
export class RewardWorkListComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  public selType: string = "99";
  public selRegistrationEvent: number = 0;
  private _title: Title;

  public p: number = 1;

  public filterName: string = "";

  public registrationEventList: any[]; //屆別
  public rewardWorkListGroup: any[];
  public rewardWorkList: any[]; //得獎作品

  public rewardWorkFilter: IRewardWorkFilter = {
    name: this.filterName,
    type: Number(this.selType),
    lang: Number(localStorage.getItem('Language'))
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("creation");
    this.navSel.emit("/RewardWorkList");

    //document.getElementById("defaultOpen").click();//tabcontent open

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 得獎作品");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Awarded Works");
    }

    //取得屆別
    this._http.get(this._baseUrl + 'RewardWork/InitClass').subscribe((response) => {
      this.registrationEventList = response['data'];
      this.selRegistrationEvent = this.registrationEventList[ this.registrationEventList.length - 1 ].id;

      this._http.post(this._baseUrl + 'RewardWork/SearchRewardWorkByFilter', this.rewardWorkFilter).subscribe((response) => {
        this.rewardWorkListGroup = response['data'];
      });
    });

  }

  openCity(evt, cityName, selRegistrationId) {
    var i, tabcontent, tablinks;

    // Get all elements with class="tabcontent" and hide them
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = "block";
    evt.currentTarget.className += " active";

    this.selRegistrationEvent = selRegistrationId;

    this.p = 1;
  }

  SearchRewardWorkByFilter() {
    this.rewardWorkFilter = {
      name: this.filterName,
      type: Number(this.selType),
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'RewardWork/SearchRewardWorkByFilter', this.rewardWorkFilter).subscribe((response) => {
      this.p = 1;
      this.rewardWorkListGroup = response['data'];
    });
  }

}
