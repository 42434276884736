import * as CryptoJS from 'crypto-js';
//const key = "Mofia";  //金鑰;



//AES加密
export function encryptByEnAES(val: string) {

  const key = CryptoJS.enc.Utf8.parse('1769947184337136');
  const iv = CryptoJS.enc.Utf8.parse('1769947184337136');

  var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(val), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  console.log('Encrypted :' + encrypted);
  
  return encrypted;
}

//AES解密
export function decryptByDeAES(decString: string) {

  const key = CryptoJS.enc.Utf8.parse('1769947184337136');
  const iv = CryptoJS.enc.Utf8.parse('1769947184337136');

  var decrypted = CryptoJS.AES.decrypt(decString, key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
}
