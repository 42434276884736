import { Component, OnInit, Output, EventEmitter, ViewChild, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-recruitment-method',
  templateUrl: './recruitment-method.component.html',
  styleUrls: ['./recruitment-method.component.css']
})
export class RecruitmentMethodComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public recruitmentMethod: string = "";

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;

  }

  ngOnInit() {
    this.closeNav.emit("volunteer");
    this.navSel.emit("/RecruitmentMethod");
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 招募辦法");

    const filter = {
      type: 6,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Volunteer/InitAbout', filter).subscribe((response) => {
      this.recruitmentMethod = response['data'].html;
    });
  }
}
