import { Component, OnInit, Output, EventEmitter, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-exhibit-main',
  templateUrl: './exhibit-main.component.html',
  styleUrls: ['./exhibit-main.component.css', '../../../assets/CustomizationMofia/commons/page/css/am-pagination.css', '../../../assets/CustomizationMofia/exhibit/css/exhibit.css', '../../../assets/CustomizationMofia/commons/page/css/form.css']
})
export class ExhibitMainComponent implements OnInit  {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _activatedRoute: ActivatedRoute;
  type: string;
  exhibitTitle: string;
    raoType: string;
  _hide: boolean;
  exhibit: any[];
  InitExhibit: any[];
  tempType: string;
  txtSearch1: string; //查詢第一個欄位
  txtSearch2: string; //查詢第二個欄位
  p: number;
  private _title: Title;
  

  public Lang: string;
  public SearchStatus: number; //搜尋狀態，1:正在查詢；2:查詢完成；2:查無資料

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._activatedRoute = activatedRoute;
    this._title = titleService;
    this.SearchStatus = 0;
  }

  ngOnInit() {
    this.closeNav.emit("exhibit");
    this.raoType = "1";
    this._hide = true;

    this.Lang = localStorage.getItem('Language');

    this.txtSearch1 = "";
    this.txtSearch2 = "";

    this.type = this._activatedRoute.snapshot.paramMap.get('type');
    const Type = {
      type: this.type,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Exhibit/InitExhibit', Type).subscribe((response) => {
      this.exhibit = response['data'];
      this.InitExhibit = this.exhibit;
    });
  }

  ngDoCheck() {
    this.type = this._activatedRoute.snapshot.paramMap.get('type');

    if (this.type === "Current") {
      sessionStorage.setItem('Exhibit', "Current");
      this.exhibitTitle = this.Lang === '1' ? "當期特展" : 'Current Exhibition';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 當期特展" : 'Museum Of Fiber Arts - Current Exhibition');
      this.navSel.emit("/ExhibitMain/Current");
    }
    else if (this.type === "Notice") {
      sessionStorage.setItem('Exhibit', "Notice");
      this.exhibitTitle = this.Lang === '1' ? "展覽預告" : 'Exhibition Preview';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 展覽預告" : 'Museum Of Fiber Arts - Exhibition Preview');
      this.navSel.emit("/ExhibitMain/Notice");
    }
    else {
      sessionStorage.setItem('Exhibit', "Review");
      this.exhibitTitle = this.Lang === '1' ? "展覽回顧" : 'Exhibition Review';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 展覽回顧" : 'Museum Of Fiber Arts - Exhibition Review');
      this.navSel.emit("/ExhibitMain/Review");
    }




    if (this.tempType !== this.type) {
      this.raoType = "1";
      this._hide = true;

      this.txtSearch1 = "";
      this.txtSearch2 = "";

      window.scrollTo(0, 0);
      this.tempType = this.type;

      const Type = {
        type: this.type,
        lang: localStorage.getItem('Language')
      }

      this._http.post(this._baseUrl + 'Exhibit/InitExhibit', Type).subscribe((response) => {
        this.p = 1;
        this.exhibit = response['data'];
        this.InitExhibit = this.exhibit;
      });
    }
    
  }

  changeType() {
    this.txtSearch1 = "";
    this.txtSearch2 = "";
    if (this.raoType === "1") {
      this._hide = false;
    }
    else {
      this._hide = true;
    }
  }

  //查詢展覽
  SearchExhibit() {
    //this.SearchStatus = 1;
    document.getElementById("statusMessage").textContent = "正在搜尋";

    //日期
    if (this.raoType === "1") {

      if (this.txtSearch1 === "" && this.txtSearch2 === "") {
        this.exhibit = this.InitExhibit;
      }
      else if (this.txtSearch1 !== "" && this.txtSearch2 !== "") {
        const SearchSDate = new Date(this.txtSearch1);
        const SearchEDate = new Date(this.txtSearch2);
        this.p = 1;

        //console.log(this.InitExhibit);

        //console.log(new Date(this.InitExhibit[0].endDate.split('T')[0]) >= SearchSDate);
        //console.log(new Date(this.InitExhibit[0].startDate.split('T')[0]) < SearchEDate);


        this.exhibit = this.InitExhibit.filter(item => new Date(item.endDate.split('T')[0]) >= SearchSDate && new Date(item.startDate.split('T')[0]) < SearchEDate);
        
      }
      else {
        Swal.fire({
          title: "日期不可為空",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false
        });
      }
      //this.SearchStatus = 2;
      document.getElementById("statusMessage").textContent = "搜尋成功";
    }
    else {  //展覽名稱
      if (this.txtSearch1 === "") {
        this.p = 1;
        this.exhibit = this.InitExhibit;
      }
      else {
        this.p = 1;
        this.exhibit = this.exhibit.filter(item => item.name.includes(this.txtSearch1));
      }
      //this.SearchStatus = 2;
      document.getElementById("statusMessage").textContent = "搜尋成功";
    }

    // 模擬一段時間後查詢成功的情況
    setTimeout(function () {
      // 更新訊息為"查詢成功"
      document.getElementById("statusMessage").textContent = "";
    }, 3000); // 這裡的2000表示2秒後更新訊息
  }
}
