import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { decryptByDeAES } from '@model/share';
import { IWorkshopMember, IPostWorkshopMemberInfo } from '@model/workshop';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop-member',
  templateUrl: './workshop-member.component.html',
  styleUrls: [
    './workshop-member.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/login.css',
    '../../../assets/CustomizationMofia/register/css/register.css'
  ]
})
export class WorkshopMemberComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public tempAddress: string;
  public tempEmail: string;
  public tempPhone: string;

  public isErrorEmail: boolean = false;
  public isErrorPhone: boolean = false;
  public errorEmailMsg: string = "";
  public errorPhoneMsg: string = "";

  public formChangePassword: FormGroup;

  public editMode: boolean = false;

  public workshopMember: IWorkshopMember = {
    id: 0,
    idNumber: "",
    name: "",
    address: "",
    email: "",
    phone: "",
    isHint: 0
  }

  public postWorkshopMemberInfo: IPostWorkshopMemberInfo = {
    id: 0,
    address: "",
    email: "",
    phone: ""
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  @ViewChild('modalChangePassword', { static: false }) modalChangePassword: ElementRef;
  @ViewChild('closeModalChangePassword', { static: false }) closeModalChangePasswordBtn: ElementRef;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, public fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("registration");
    this.navSel.emit("/WorkshopMember/Info");
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 學員專區");

    if (sessionStorage.getItem('WorkshopMemberId') != null) {
      if (decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')) != "") {
        //已登入直接抓會員資料
        this._http.post(this._baseUrl + 'Workshop/SearchWorkshopMember', Number(decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')))).subscribe((response) => {

            if (response['codeThird'] === "0000") {
              this.workshopMember = response['data'];
              this.IsShowHint();
            }
            else {
              Swal.fire({
                title: response['msg'],
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
                preConfirm: function () {
                  sessionStorage.removeItem("WorkshopMemberId");
                  this._router.navigate(['/WorkshopMember/Login']);
                }
              });
            }
          });
      }
      else {
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
    }
    else {
      sessionStorage.removeItem("WorkshopMemberId");
      this._router.navigate(['/WorkshopMember/Login']);
    }

    this.formChangePassword = this.fb.group({
      pwdOldPassword: [null, [Validators.required, Validators.minLength(6)]],
      pwdNewPassword1: [null, [Validators.required, Validators.minLength(6)]],
      pwdNewPassword2: [null, [Validators.required, Validators.minLength(6)]]
    });
  }

  EditMemberInfo() {
    this.editMode = true;
    this.tempAddress = this.workshopMember.address;
    this.tempEmail = this.workshopMember.email;
    this.tempPhone = this.workshopMember.phone;
  }

  SaveMemberInfo() {
    //檢查驗證
    if (!this.CheckRegExp(this.workshopMember)) {
      return;
    }

    this.postWorkshopMemberInfo = {
      id: this.workshopMember.id,
      address: this.workshopMember.address,
      email: this.workshopMember.email,
      phone: this.workshopMember.phone
    }

    this._http.post(this._baseUrl + 'Workshop/SaveWorkshopMemberInfo', this.postWorkshopMemberInfo).subscribe((response) => {
      if (response['codeThird'] == "0000") {
        this.Toast.fire({
          icon: 'success',
          title: response['msg'],
          timer: 2500
        });

        this.editMode = false;
      }
      else if (response['codeThird'] == "0001") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 2500
        });

        //強制登出
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
      else if (response['codeThird'] == "9999") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000
        });

        //強制登出
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
    });
  }

  CheckRegExp(workshopMember: any) {
    var patternEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var patternPhone = /(^0\\d{1,2}-?(\\d{6,8})$)|(^09[0-9]{8}$)/;
    if (!patternEmail.test(workshopMember.email)) {
      this.isErrorEmail = true;
      this.errorEmailMsg += "電子信箱格式錯誤，請重新輸入。";
    }
    else {
      this.isErrorEmail = false;
      this.errorEmailMsg = "";
    }

    if (!patternPhone.test(workshopMember.phone)) {
      this.isErrorPhone = true;
      this.errorPhoneMsg += "電話格式是錯誤，請重新輸入。";
    }
    else {
      this.isErrorPhone = false;
      this.errorPhoneMsg = "";
    }

    if (this.isErrorEmail || this.isErrorPhone) {
      return false;
    }
    return true;
  }

  CancelEditMode() {
    //取消編輯
    this.editMode = false;
    this.workshopMember.address = this.tempAddress;
    this.workshopMember.email = this.tempEmail;
    this.workshopMember.phone = this.tempPhone;
  }

  LoginOut() {

    this.Toast.fire({
      icon: 'success',
      title: '登出成功',
      timer: 2500
    });

    sessionStorage.removeItem("WorkshopMemberId");
    this._router.navigate(['/WorkshopMember/Login']);
  }

  //修改密碼
  ChangPassword() {

    var postPassword = {
      id: this.workshopMember.id,
      oldPWD: this.formChangePassword.get("pwdOldPassword").value,
      newPWD1: this.formChangePassword.get("pwdNewPassword1").value,
      newPWD2: this.formChangePassword.get("pwdNewPassword2").value
    }

    this._http.post(this._baseUrl + 'Workshop/SaveWorkshopMemberPassword', postPassword).subscribe((response) => {
      if (response['codeThird'] == "0000") {
        this.Toast.fire({
          icon: 'success',
          title: response['msg'],
          timer: 2500,
          customClass: {
            container: 'my-swal'
          }
        });

        this.closeModalChangePasswordBtn.nativeElement.click();
      }
      else if (response['codeThird'] == "0001") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 2500,
          customClass: {
            container: 'my-swal'
          }
        });

        //強制登出
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
      else if (response['codeThird'] == "0002" || response['codeThird'] == "0003") {
        this.Toast.fire({
          icon: 'warning',
          title: response['msg'],
          timer: 2500,
          customClass: {
            container: 'my-swal'
          }
        });
      }
      else if (response['codeThird'] == "9999") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          customClass: {
            container: 'my-swal'
          }
        });

        //強制登出
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
    });
  }

  IsShowHint() {
    if (this.workshopMember.isHint == 1) {
      Swal.fire({
        html: "第一次登入建議變更密碼，請問是否要更換密碼?",
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '好',
        cancelButtonText: '暫時不要',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.modalChangePassword.nativeElement.click();
        }
      });
    }
  }

}
