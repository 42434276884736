import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-bulletin-list',
  templateUrl: './bulletin-list.component.html',
  styleUrls: [
    './bulletin-list.component.css',
    '../../../assets/CustomizationMofia/creation/css/creation.css'
  ]
})
export class BulletinListComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  p: number = 1;
  public Lang: string;
  private _title: Title;
  tablinkVal: number;

  public BulletinList: any[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("/NewsList");
    this.tablinkVal = 0;

    var filter = {
      type: this.tablinkVal,
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'Bulletin/SearchBulletinByFilter', filter).subscribe((response) => {
      this.BulletinList = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 最新消息");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - News");
    }

  }

  AddPoint(id: number) {
    this._http.post(this._baseUrl + 'Bulletin/AddPoint', id).subscribe((response) => {
    });
  }

  changeTabLinks(val: number) {
    this.tablinkVal = val;

    const filter = {
      type: val,
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'Bulletin/SearchBulletinByFilter', filter).subscribe((response) => {
      this.BulletinList = response['data'];
    });
  }

}
