import { Component, OnInit, Output, EventEmitter, Inject, ElementRef, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ICollection } from '@model/collection-research';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.css']
})
export class CollectionComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  private _title: Title;

  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;
  @ViewChild('collectionDiv', { static: true }) simple;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    accessibility: false,
    nextArrow: '<button class="slick-next" aria-label="Next" type="button">Next</button>',
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  public collection: ICollection = {
    id: 0,
    name: "",
    html: "",
    imgs: [],
    annexFileList: null
  }

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this.collection.id = Number(activatedRoute.snapshot.paramMap.get('id'));
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("");

    const getEnglishId = {
      type: 2,
      Id: this.collection.id,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Exhibit/getEnglishExhibitId', getEnglishId).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        if (response['data'].isChangeLang) {
          if (response['data'].id !== 0) {
            this.collection.id = response['data'].id;
            this._router.navigate(['/CollectionList/' + response['data'].id]);
          }


          this._http.post(this._baseUrl + 'CollectionResearch/SearchCollection', this.collection.id).subscribe((response) => {

            if (response['codeThird'] === "0000") {
              this.collection.id = response['data'].id;
              this.collection.name = response['data'].name;
              this.simple.nativeElement.innerHTML = response['data'].html;
              this.collection.imgs = response['data'].imgs;
              this.collection.annexFileList = response['data'].annexFileList;
            }
            else {
              Swal.fire({
                title: response['msg'],
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
              }).then((result) => {
                this._router.navigate(['/CollectionList']);
              });
            }
          });
        }
        else {
          this._router.navigate(['/CollectionList']);
        }
      }
      else {
        Swal.fire({
          title: "系統發生錯誤請聯絡纖博",
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/CollectionList']);
        });
      }
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 典藏精選");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Collection Selection");
    }
  }

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }
}
