import { Component, OnInit, Output, EventEmitter, ViewChild, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-exhibit',
  templateUrl: './exhibit.component.html',
  styleUrls: [
    './exhibit.component.css',
    '../../../assets/CustomizationMofia/exhibit/css/exhibit.css',
    '../../../assets/CustomizationMofia/commons/fancybox/css/main.css',
  ]
})
export class ExhibitComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  @ViewChild('slickModal', { static: true }) slickModal: SlickCarouselComponent;

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    accessibility: false,
    nextArrow: '<button class="slick-next" aria-label="Next" type="button">Next</button>',
    prevArrow: '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 980,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  id: number;

  public exhibit = {
    id: 0,
    name: "",
    mainIcon: "",
    eImage: [],
    show: null,
    introduction: "",
    annex: null
  }

  public Lang: string;
  typeName: string;
  private _activatedRoute: ActivatedRoute

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._activatedRoute = activatedRoute;
    this.id = Number(activatedRoute.snapshot.paramMap.get('id'));
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");

    this.Lang = localStorage.getItem('Language');

    if (sessionStorage.getItem('Exhibit') === "Current") {
      this.typeName = this.Lang === '1' ? "當期特展" : 'Current Exhibition';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 當期特展" : 'Museum Of Fiber Arts - Current Exhibition');
    }
    else if (sessionStorage.getItem('Exhibit') === "Notice") {
      this.typeName = this.Lang === '1' ? "展覽預告" : 'Exhibition Preview';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 展覽預告" : 'Museum Of Fiber Arts - Exhibition Preview');
    }
    else {
      this.typeName = this.Lang === '1' ? "展覽回顧" : 'Exhibition Review';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 展覽回顧" : 'Museum Of Fiber Arts - Exhibition Review');
    }

    const getEnglishId = {
      type: 1,
      Id: this.id,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Exhibit/getEnglishExhibitId', getEnglishId).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        if (response['data'].isChangeLang) {
          if (response['data'].id !== 0) {
            this.id = response['data'].id;
            this._router.navigate(['/Exhibit/' + response['data'].id]);
          }

          const Id = {
            id: this.id,
            lang: localStorage.getItem('Language')
          }

          this._http.post(this._baseUrl + 'Exhibit/InitExhibitDetail', Id).subscribe((response) => {
            if (response['codeThird'] === "0000") {
              this.exhibit.name = response['data'].name;
              this.exhibit.mainIcon = response['data'].mainIcon;
              this.exhibit.eImage = response['data'].eImage;
              this.exhibit.show = response['data'].show;
              this.exhibit.introduction = response['data'].introduction;
              this.exhibit.annex = response['data'].annex;
            }
            else {
              Swal.fire({
                title: "系統發生錯誤請聯絡纖博",
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
              }).then((result) => {
                this._router.navigate(['/ExhibitMain/Current']);
              });
            }
          });
        }
        else {
          this._router.navigate(['/ExhibitMain/Current']);
        }
      }
      else {
        Swal.fire({
          title: "系統發生錯誤請聯絡纖博",
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/ExhibitMain/Current']);
        });
      }
    });
  }

  next() {
    this.slickModal.slickNext();
  }
  prev() {
    this.slickModal.slickPrev();
  }

  //BackHistory() {
  //  this._router.;
  //}
}
