import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})
export class OrganizationComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  organizationHtml: string;
  organizationImg: string;
  private _title: Title;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("about");
    this.navSel.emit("/Organization");

    const filter = {
      type: 3,
      lang: localStorage.getItem('Language')
    }

    this._http.post(this._baseUrl + 'Volunteer/InitAbout', filter).subscribe((response) => {
      this.organizationHtml = response['data'].html;
      this.organizationImg = response['data'].organizationImg;
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 組織架構");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Organization");
    }
  }
}
