import { Component, OnInit, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { INews } from '@model/news';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';
import { NgxLinkifyjsService } from 'ngx-linkifyjs';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.css']
})
export class BulletinComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _activatedRoute: ActivatedRoute;
  companyVision: string;
  public Lang: string;
  private _title: Title;

  public bulletin = {
    id: 0,
    name: "",
    description: "",
    date: "",
    img: "",
    annex: null
  }

  @ViewChild('newsDiv', { static: true }) simple;

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private titleService: Title, public linkifyService: NgxLinkifyjsService) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this.bulletin.id = Number(activatedRoute.snapshot.paramMap.get('id'));

    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("/BulletinList");

    const getEnglishId = {
      type: 6,
      Id: this.bulletin.id,
      lang: localStorage.getItem('Language')
    }
    this._http.post(this._baseUrl + 'Exhibit/getEnglishExhibitId', getEnglishId).subscribe((response) => {
      if (response['codeThird'] === "0000") {
        if (response['data'].isChangeLang) {
          if (response['data'].id !== 0) {
            this.bulletin.id = response['data'].id;
            this._router.navigate(['/BulletinList/' + response['data'].id]);
          }

          this._http.post(this._baseUrl + 'Bulletin/SearchBulletin', this.bulletin.id).subscribe((response) => {

            if (response['codeThird'] === "0000") {
              this.bulletin.id = response['data'].id;
              this.bulletin.name = response['data'].name;
              //this.news.type = response['data'].type;
              //this.news.description = this.linkifyService.linkify(response['data'].description);
              this.simple.nativeElement.innerHTML = response['data'].description
              this.bulletin.date = response['data'].date;
              this.bulletin.img = response['data'].img;
              //this.news.point = response['data'].point;
              this.bulletin.annex = response['data'].annex;
            }
            else {
              Swal.fire({
                title: response['msg'],
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: '確認',
                allowOutsideClick: false,
              }).then((result) => {
                this._router.navigate(['/BulletinList']);
              });
            }
          });
        }
        else {
          this._router.navigate(['/BulletinList']);
        }
      }
      else {
        Swal.fire({
          title: "系統發生錯誤請聯絡纖博",
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          this._router.navigate(['/BulletinList']);
        });
      }
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 最新消息");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - New");
    }
  }
}
