import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.css']
})
export class SitemapComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  public router: string = "";
  private _baseUrl: string;
  private _http: HttpClient;

  public Language: number;
  public IsMActivity: boolean = false;  //是否有纖維創作獎報名活動

  private _title: Title;


  constructor(private _router: Router, http: HttpClient, @Inject('BASE_URL') baseUrl: string, private titleService: Title) {
    this.router = _router.url;
    this._http = http;
    this._baseUrl = baseUrl;
    this._title = titleService;
  }

  ngOnInit() {

    this.closeNav.emit("");
    this.navSel.emit("");

    if (localStorage.getItem('Language') !== null) {
      if (localStorage.getItem('Language') === "1") {
        //中文
        this.Language = 1;
      }
      else if (localStorage.getItem('Language') === "2") {
        //英文
        this.Language = 2;
      }

    }
    else {
      //中文
      this.Language = 1;
      localStorage.setItem('Language', "1");
    }

    if (this.Language == 1) {
      this._title.setTitle("臺中市纖維工藝博物館 - 網站導覽");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - SiteMap");
    }

    //判斷是否有纖維創作獎線上報名活動
    this._http.get(this._baseUrl + 'Creation/InitClassActivity').subscribe((response) => {
      if (response['data'] !== null) {
        this.IsMActivity = true;
      }
      else {
        this.IsMActivity = false;
      }
    });
  }

}
