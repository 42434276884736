import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: [
    './news-list.component.css',
    '../../../assets/CustomizationMofia/creation/css/creation.css'
  ]
})
export class NewsListComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  p: number = 1;
  public Lang: string;
  private _title: Title;

  public NewsList: any[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("creation");
    this.navSel.emit("/NewsList");

    var filter = {
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'News/SearchNewsByFilter', filter).subscribe((response) => {
      this.NewsList = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 最新消息");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - News");
    }

  }

  AddPoint(id: number) {
    this._http.post(this._baseUrl + 'News/AddPoint', id).subscribe((response) => {
    });
  }

}
