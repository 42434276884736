import { Component, OnInit, Output, EventEmitter, Inject, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { IPublishFilter } from '@model/publish';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-publish-main',
  templateUrl: './publish-main.component.html',
  styleUrls: [
    './publish-main.component.css',
    '../../../assets/CustomizationMofia/publish/css/publish.css',
    '../../../assets/CustomizationMofia/commons/page/css/am-pagination.css',
  ]
})
export class PublishMainComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _activatedRoute: ActivatedRoute;
  p: number = 1;
  public Lang: string;
  type: string;
  public publishType: number = 1;

  private _title: Title;

  tempType: string = "";

  filter: IPublishFilter = {
    name: "",
    type: 1,
    lang: Number(localStorage.getItem('Language'))
  }

  public publishTitle: string = "";
  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, private el: ElementRef, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._activatedRoute = activatedRoute;
    this._title = titleService;
  }

  public publishList: any[];
  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("publish");

    this.type = this._activatedRoute.snapshot.paramMap.get('type');
    if (this.type === "Publish") {
      this.filter.type = 1;
      this.publishType = 1;
    }
    else if (this.type === "Cultural") {
      this.filter.type = 2;
      this.publishType = 2;
    }
    else if (this.type === "Authoriza") {
      this.filter.type = 3;
      this.publishType = 3;
    }

    this._http.post(this._baseUrl + 'Publish/SearchPublishByFilter', this.filter).subscribe((response) => {

      if (response['codeThird'] === "0000") {
        this.publishList = response['data'];
      }
    });
  }

  ngDoCheck() {
    this.type = this._activatedRoute.snapshot.paramMap.get('type');

    
    if (this.type === "Publish") {
      this.publishTitle = this.filter.lang === 1 ? "出版品" : 'Publications';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 出版品" : 'Museum Of Fiber Arts - Publications');
      this.publishType = 1;
      this.navSel.emit("/PublishMain/Publish");

    }
    else if (this.type === "Cultural") {
      this.publishTitle = this.filter.lang === 1 ? "文創商品" : 'Cultural Creations';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 文創商品" : 'Museum Of Fiber Arts - Cultural Creations');
      this.publishType = 2;
      this.navSel.emit("/PublishMain/Cultural");

    }
    else if (this.type === "Authoriza") {
      this.publishTitle = this.filter.lang === 1 ? "圖像授權" : 'Image Licensing';
      this._title.setTitle(this.Lang === '1' ? "臺中市纖維工藝博物館 - 圖像授權" : 'Museum Of Fiber Arts - Image Licensing');
      this.publishType = 3;
      this.navSel.emit("/PublishMain/Authoriza");

    }

    //判斷 是否有切換，有則重新撈資料
    if (this.tempType != this.type) {
      window.scrollTo(0, 0);
      this.tempType = this.type;

      this.filter = {
        name: "",
        type: this.publishType,
        lang: Number(localStorage.getItem('Language'))
      }

      this._http.post(this._baseUrl + 'Publish/SearchPublishByFilter', this.filter).subscribe((response) => {
        if (response['codeThird'] === "0000") {
          this.p = 1;
          this.publishList = response['data'];
        }
      });
    }
  }

  SearchPublishByFilter(form: any) {

    this.filter = {
      name: form.txtFilterName,
      type: this.publishType,
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'Publish/SearchPublishByFilter', this.filter).subscribe((response) => {
      this.p = 1;
      this.publishList = response['data'];
    });
  }
}
