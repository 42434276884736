import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IPostWorkshopMemberForget } from '@model/workshop';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-workshop-member-forget',
  templateUrl: './workshop-member-forget.component.html',
  styleUrls: [
    './workshop-member-forget.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/login.css',
    '../../../assets/CustomizationMofia/register/css/register.css'
  ]
})
export class WorkshopMemberForgetComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _ErrorMsg: string;
  public _captchaUrl: string;
  public _captchaAlt: string;
  public idNumber: string = "";
  public phone: string = "";
  public captcha: string = "";
  private _title: Title;
  public formReset: FormGroup;

  public isErrorIdNumber: boolean = false;
  public isErrorPhone: boolean = false;
  public errorIdNumberMsg: string = "";
  public errorPhoneMsg: string = "";

  public postWorkshopMemberForget: IPostWorkshopMemberForget = {
    idNumber: "",
    phone: "",
    captcha: ""
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title, private fb: FormBuilder) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._captchaUrl = "";
    this._captchaAlt = "";
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");
    this.isLangDisplay.emit(true);
    this._title.setTitle("臺中市纖維工藝博物館 - 學員專區");

    this.formReset = this.fb.group({
      txtIdNumber: [null, [Validators.required, Validators.pattern(new RegExp("^[a-zA-Z][0-9]{9}$"))]],
      txtPhone: [null, [Validators.required, Validators.pattern(new RegExp("(^0\\d{1,2}-?(\\d{6,8})$)|(^09[0-9]{8}$)"))]],
      txtCaptcha: [null, [Validators.required]]
    });

    this._http.get(this._baseUrl + 'Workshop/Captcha').subscribe(
      (response) => {
        this._captchaUrl = response['img'];

        this._captchaAlt = response['text'];
      });
  }

  //重新產生數字圖形驗證碼
  resetCaptcha(): void {
    this._http.get(this._baseUrl + 'Workshop/Captcha').subscribe(
      (response) => {
        this._captchaUrl = response['img'];

        this._captchaAlt = response['text'];
      });
  }

  //清除錯誤訊息
  clearMessage(): void {
    this._ErrorMsg = "";
  }

  ResetPassword() {

    if (!this.formReset.valid) {
      this.scrollToError();
      return;
    }
    if (this.idNumber != "" && this.phone != "" && this.captcha != "") {
      this.clearMessage();

      this.postWorkshopMemberForget = {
        idNumber: this.idNumber,
        phone: this.phone,
        captcha: this.captcha
      }

      
        this._http.post(this._baseUrl + 'Workshop/ResetMemberPassword', this.postWorkshopMemberForget).subscribe((response) => {
          if (response['codeThird'] == "0000") {
            this.Toast.fire({
              icon: 'success',
              title: response['msg'],
              timer: 2500
            });

            sessionStorage.removeItem("WorkshopMemberId");
            this._router.navigate(['/WorkshopMember/Login']);
          }
          else if (response['codeThird'] == "0001") {
            this.Toast.fire({
              icon: 'warning',
              title: response['msg'],
              timer: 2500
            });
          }
          else if (response['codeThird'] == "9999") {
            this.Toast.fire({
              icon: 'error',
              title: response['msg'],
              timer: 5000
            });
          }
        });
    }

  }

  //CheckRegExp() {
  //  var patternIdNumber = /^[a-zA-Z][0-9]{9}$/;
  //  var patternPhone = /(^0\\d{1,2}-?(\\d{6,8})$)|(^09[0-9]{8}$)/;
  //  if (!patternIdNumber.test(this.idNumber)) {
  //    this.isErrorIdNumber = true;
  //    this.errorIdNumberMsg += "身分證號格式錯誤，請重新輸入。";
  //  }
  //  else {
  //    this.isErrorIdNumber = false;
  //    this.errorIdNumberMsg = "";
  //  }

  //  if (!patternPhone.test(this.phone)) {
  //    this.isErrorPhone = true;
  //    this.errorPhoneMsg += "電話格式是錯誤，請重新輸入。";
  //  }
  //  else {
  //    this.isErrorPhone = false;
  //    this.errorPhoneMsg = "";
  //  }

  //  if (this.isErrorIdNumber || this.isErrorPhone) {
  //    return false;
  //  }
  //  return true;
  //}

  //驗證碼語音服務
  async videoCaptcha() {
    const chars = this._captchaAlt.split('');
    const audio1 = new Audio();
    audio1.src = "../../assets/numMp3/" + chars[0] + ".mp3";
    audio1.play();
    audio1.addEventListener('ended', function () {
      const audio2 = new Audio();
      audio2.src = "../../assets/numMp3/" + chars[1] + ".mp3";
      audio2.play();

      audio2.addEventListener('ended', function () {
        const audio3 = new Audio();
        audio3.src = "../../assets/numMp3/" + chars[2] + ".mp3";
        audio3.play();

        audio3.addEventListener('ended', function () {
          const audio4 = new Audio();
          audio4.src = "../../assets/numMp3/" + chars[3] + ".mp3";
          audio4.play();
        }, false);
      }, false);
    }, false);
  }

  scrollTo(el: Element): void {
    if (el) {
      //el.scrollIntoView({ behavior: 'smooth' });
      //var elementPosition = el.getBoundingClientRect().top;
      //var offsetPosition = elementPosition + window.pageYOffset - 140;

      //window.scrollTo({ top: offsetPosition, behavior: 'smooth' });

      document.getElementById(el.id).focus();
    }
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}
