import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IActivityLecture } from '@model/activity-lecture';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-activity-lecture-registration',
  templateUrl: './activity-lecture-registration.component.html',
  styleUrls: [
    './activity-lecture-registration.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/form.css'
  ]
})
export class ActivityLectureRegistrationComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;

  public formActivityLecture: FormGroup;

  public activityLectureId: number = 0;
  private _title: Title;


  public activityLecture: IActivityLecture = {
    id: 0,
    name: "", //名稱
    type: 0, //類型
    session: "", //場次
    date: "", //日期
    venue: "", //地點
    quota: 0, //名額
    banner: "", //圖片
    fee: 0, //費用
    feeDeadline: "", //繳費期限
    feeRemark: "", //費用備註
    introduction: "", //內容介紹
    prospectiveStudent: "", //招生對象
    minCapacity: 0, //開班人數
    alternate: 0, //候補人數
    numberOfRegistration: 0, //報名人數
    numberOfPaid: 0,
    remainingNumber: 0, //剩餘人數
    classStatus: 0, //開班狀態
    organizer: "臺中市纖維工藝博物館", //主辦單位
    contactInformation: "", //聯絡方式
    remark: "" //備註
  }

  public activityLectureRegistration = {
    activityLectureId: 0,
    name: "",
    phone: "",
    number: 0,
    parentAccompany: 0,
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, activatedRoute: ActivatedRoute, public fb: FormBuilder, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;

    this.activityLectureId = Number(activatedRoute.snapshot.paramMap.get('id'));
    this.activityLectureRegistration.activityLectureId = this.activityLectureId;

    this.formActivityLecture = this.fb.group({
      name: [null, Validators.required],
      number: [null, [Validators.required, Validators.min(1)]],
      phone: [null, [Validators.required, Validators.pattern(new RegExp("(^0\\d{1,2}-?(\\d{6,8})$)|(^09[0-9]{8}$)"))]],
      parentAccompany: [null]
    });

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");
  }

  ngOnInit() {
    this.closeNav.emit("");
    this.navSel.emit("");
    this.isLangDisplay.emit(true);

    //取得該研習內容
    this._http.post(this._baseUrl + 'ActivityLecture/SearchActivity', this.activityLectureId).subscribe((response) => {
      if (response['data'] != null || response['data'] != undefined) {
        this.activityLecture = response['data'];
      }
      else {
        this._http.post(this._baseUrl + 'ActivityLecture/SearchLecture', this.activityLectureId).subscribe((response) => {
          if (response['data'] != null || response['data'] != undefined) {
            this.activityLecture = response['data'];
          }
        });
      }

      //比較時間可否報名
      var now = new Date();
      var activityLectureDate = new Date(this.activityLecture.date);
      if (now > activityLectureDate) {
        if (this.activityLecture.type == 1) {
          this._router.navigate(['/ActivityList/' + this.activityLecture.id]);
        }
        else if (this.activityLecture.type == 2){
          this._router.navigate(['/LectureList/' + this.activityLecture.id]);
        }
      }

      if (this.activityLecture.type == 1) {
        //活動
        if ((this.activityLecture.quota + this.activityLecture.alternate) <= this.activityLecture.numberOfPaid) {
          this._router.navigate(['/ActivityList/' + this.activityLecture.id]);
        }
      }
      else if (this.activityLecture.type == 2) {
        //講座
        if ((this.activityLecture.quota + this.activityLecture.alternate) <= this.activityLecture.numberOfRegistration) {
          this._router.navigate(['/LectureList/' + this.activityLecture.id]);
        }
      }
    });

  }

  SaveActivityLecture() {
    if (!this.formActivityLecture.valid) {
      return;
    }

    this._http.post(this._baseUrl + 'ActivityLecture/SaveActivityLectureRegistration', this.activityLectureRegistration).subscribe((response) => {

      if (response['codeThird'] == "0000") {
        Swal.fire({
          title: response['msg'],
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '確認',
          allowOutsideClick: false,
        }).then((result) => {
          console.log(this.activityLecture.type);
          if (this.activityLecture.type == 1) {
            this._router.navigate(['/ActivityList/' + this.activityLecture.id]);
          }
          else if (this.activityLecture.type == 2) {
            this._router.navigate(['/LectureList/' + this.activityLecture.id]);
          }
        });
      }
      else if (response['codeThird'] == "9999") {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000,
          showConfirmButton: false
        }).then((result) => {
          if (this.activityLecture.type == 1) {
            this._router.navigate(['/ActivityList/' + this.activityLecture.id]);
          }
          else if (this.activityLecture.type == 2) {
            this._router.navigate(['/LectureList/' + this.activityLecture.id]);
          }
        });
      }
      else {
        this.Toast.fire({
          title: response['msg'],
          icon: 'warning',
          timer: 5000,
          showConfirmButton: false
        }).then((result) => {
          if (this.activityLecture.type == 1) {
            this._router.navigate(['/ActivityList/' + this.activityLecture.id]);
          }
          else if (this.activityLecture.type == 2) {
            this._router.navigate(['/LectureList/' + this.activityLecture.id]);
          }
        });
      }
    });
  }

}
