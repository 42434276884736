import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-research-list',
  templateUrl: './research-list.component.html',
  styleUrls: ['./research-list.component.css']
})
export class ResearchListComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  public Lang: string;
  private _title: Title;

  p: number = 1;

  public filterName: string = "";

  public researchList: any[];
  public tempResearchList: any[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.Lang = localStorage.getItem('Language');
    this.closeNav.emit("");
    this.navSel.emit("");

    var filter = {
      name: "",
      lang: Number(localStorage.getItem('Language'))
    }

    this._http.post(this._baseUrl + 'CollectionResearch/SearchResearchByFilter', filter).subscribe((response) => {
      this.researchList = response['data'];
      this.tempResearchList = response['data'];
    });

    if (this.Lang == '1') {
      this._title.setTitle("臺中市纖維工藝博物館 - 研究");
    }
    else {
      this._title.setTitle("Museum Of Fiber Arts - Research");
    }
  }

  SearchResearchByFilter() {
    if (this.filterName != "") {
      this.researchList = this.researchList.filter(x => x.name.includes(this.filterName))
      this.p = 1;
    }
    else {
      this.researchList = this.tempResearchList;
      this.p = 1;
    }
  }

}
