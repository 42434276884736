import { Component, Renderer2, OnInit, Inject  } from '@angular/core';
import { HostListener } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  private _baseUrl: string;
  private _http: HttpClient;
  visitPeople: any;
  Language: number;
  updateDate: any;

  

  constructor(private _router: Router, http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this._http = http;
    this._baseUrl = baseUrl;
  }
  //@HostListener('document:keypress', ['$event'])
  //handleKeyboardEvent(event: KeyboardEvent) {
  //  console.log(event.key);
  //  //this.key = event.key;
  //}

  ngOnInit() {
    if (localStorage.getItem('Language') !== null) {
      if (localStorage.getItem('Language') === "1") {
        //中文
        this.Language = 1;
      }
      else if (localStorage.getItem('Language') === "2") {
        //英文
        this.Language = 2;
      }
    }
    else {
      //中文
      this.Language = 1;
      localStorage.setItem('Language', "1");
    }


    if (sessionStorage.getItem('LoginTimes') !== null && sessionStorage.getItem('LoginTimes') !== undefined) {
      sessionStorage.setItem('LoginTimes', "G2&mUWu*U$FV88F&AM&w");

      const VisitPeople = {
        isUpdate: false
      }
      this._http.post(this._baseUrl + 'About/GetVisitPeople', VisitPeople).subscribe((response) => {
        this.visitPeople = response["data"].visitPeople;
        this.updateDate = response["data"].updateDate;
      });
    }
    else {
      sessionStorage.setItem('LoginTimes', "*SuGhHBpxw^tV5g52RuV");

      const VisitPeople = {
        isUpdate: true
      }
      this._http.post(this._baseUrl + 'About/GetVisitPeople', VisitPeople).subscribe((response) => {
        this.visitPeople = response["data"].visitPeople;
        this.updateDate = response["data"].updateDate;
      });
    }
  }
}


