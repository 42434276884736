import { Component, OnInit, Output, EventEmitter, Inject, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
//import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';
//import { Lightbox } from '@ngx-gallery/lightbox';
declare var jQuery: any;

@Component({
  selector: 'app-activity-highlights',
  templateUrl: './activity-highlights.component.html',
  styleUrls: [
    './activity-highlights.component.css',
    '../../../assets/CustomizationMofia/commons/fancybox/css/main.css'
  ]
})
export class ActivityHighlightsComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  activity: any[];
  private _title: Title;

  //items: GalleryItem[];

  //imageData = data;


  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("volunteer");
    this.navSel.emit("/ActivityHighlights");
    this.isLangDisplay.emit(true);

    this._http.get(this._baseUrl + 'Volunteer/InitActivity').subscribe((response) => {
      this.activity = response['data'];
    });

    this._title.setTitle("臺中市纖維工藝博物館 - 活動花絮");

    /*
     * 待後續
     * https://murhafsousli.github.io/ngx-gallery/
     *
     * DEMO: https://stackblitz.com/edit/ngx-gallery?file=angular.json
     */
    //// Creat gallery items
    //this.items = this.imageData.map(item => new ImageItem({ src: item.srcUrl, thumb: item.previewUrl }));

    ///** Lightbox Example */

    //// Get a lightbox gallery ref
    //const lightboxRef = this.gallery.ref('lightbox');

    //// Add custom gallery config to the lightbox (optional)
    //lightboxRef.setConfig({
    //  imageSize: ImageSize.Cover,
    //  thumbPosition: ThumbnailsPosition.Bottom
    //});

    //// Load items into the lightbox gallery ref
    //lightboxRef.load(this.items);
  }
}

//const data = [
//  {
//    srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
//    previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
//  },
//  {
//    srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
//    previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
//  },
//  {
//    srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
//    previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
//  },
//  {
//    srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
//    previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
//  }
//];
