import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lecture-list',
  templateUrl: './lecture-list.component.html',
  styleUrls: [
    './lecture-list.component.css',
    '../../../assets/CustomizationMofia/register/css/register.css'
  ]
})
export class LectureListComponent implements OnInit {
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  @Input() parentClickDay: string;
  public tempClickDay: string = "";

  public lectureList: any[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;

    this._title = titleService;
  }

  ngOnInit() {
    this.isLangDisplay.emit(true);

    var filter = {
      selectDate: ""
    }

    this._http.post(this._baseUrl + 'ActivityLecture/SearchLectureByFilter', filter).subscribe((response) => {
      this.lectureList = response['data'];
    });

    this._title.setTitle("臺中市纖維工藝博物館 - 活動研習");
  }

  ngOnChanges() {
    this.SearchLectureByFilter();
  }

  SearchLectureByFilter() {
    if (this.parentClickDay != null && this.parentClickDay != undefined) {
      var filter = {
        selectDate: this.parentClickDay
      }

      this._http.post(this._baseUrl + 'ActivityLecture/SearchLectureByFilter', filter).subscribe((response) => {
        this.lectureList = response['data'];
      });
    }
  }

}
