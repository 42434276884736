import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-common-question',
  templateUrl: './common-question.component.html',
  styleUrls: ['./common-question.component.css']
})
export class CommonQuestionComponent implements OnInit {
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;

  private _title: Title;

  public commonQuestionList: any[];

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("convenience");
    this.navSel.emit("/WorkshopMember/CommonQuestion");
    this.isLangDisplay.emit(true);

    this._title.setTitle("臺中市纖維工藝博物館 - 常見問題");

    var filter = {
      type: 1,
    }

    this._http.post(this._baseUrl + 'Workshop/SearchCommonQuestionByFilter', filter).subscribe((response) => {

      if (response['codeThird'] === "0000") {
        this.commonQuestionList = response['data'];
      }
    });
  }

}
