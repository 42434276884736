import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { IWorkshopMemberClass, IWorkshopMemberClassFilter } from '@model/workshop';
import { decryptByDeAES } from '@model/share';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-workshop-member-class',
  templateUrl: './workshop-member-class.component.html',
  styleUrls: [
    './workshop-member-class.component.css',
    '../../../assets/CustomizationMofia/commons/page/css/login.css',
    '../../../assets/CustomizationMofia/register/css/register.css'
  ]
})
export class WorkshopMemberClassComponent implements OnInit {
  p: number = 1;
  @Output() closeNav: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() navSel: EventEmitter<string> = new EventEmitter<string>(true);
  @Output() isLangDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _baseUrl: string;
  private _http: HttpClient;
  private router: string;
  private _title: Title;

  public workshopMemberClassList: IWorkshopMemberClass[];
  public workshopMemberClassFilter: IWorkshopMemberClassFilter = {
    memberId: 0,
    startDate: "",
    endDate: ""
  }

  Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false
  });

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _router: Router, private titleService: Title) {
    this._http = http;
    this._baseUrl = baseUrl;
    this.router = _router.url;
    this._title = titleService;
  }

  ngOnInit() {
    this.closeNav.emit("registration");
    this.navSel.emit("/WorkshopMember/Info");
    this.isLangDisplay.emit(true);
    this._title.setTitle("臺中市纖維工藝博物館 - 學員專區");

    if (sessionStorage.getItem('WorkshopMemberId') != null) {
      if (decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')) != "") {

        this.workshopMemberClassFilter.memberId = Number(decryptByDeAES(sessionStorage.getItem('WorkshopMemberId')));

        this._http.post(this._baseUrl + 'Workshop/SearchWorkshopMemberClassByFilter', this.workshopMemberClassFilter).subscribe((response) => {

          if (response['codeThird'] === "0000") {
            this.workshopMemberClassList = response['data'];
          }
          else {
            this.Toast.fire({
              icon: 'error',
              title: response['msg'],
              timer: 5000
            });

            //強制登出
            sessionStorage.removeItem("WorkshopMemberId");
            this._router.navigate(['/WorkshopMember/Login']);
          }
        });
      }
      else {
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
    }
    else {
      sessionStorage.removeItem("WorkshopMemberId");
      this._router.navigate(['/WorkshopMember/Login']);
    }
  }

  SearchWorkshopMemberClassByFilter(from: any) {

    this.workshopMemberClassFilter = {
      memberId: Number(decryptByDeAES(sessionStorage.getItem('WorkshopMemberId'))),
      startDate: from.dateFilterWorkshopStart,
      endDate: from.dateFilterWorkshopEnd
    }

    this._http.post(this._baseUrl + 'Workshop/SearchWorkshopMemberClassByFilter', this.workshopMemberClassFilter).subscribe((response) => {

      if (response['codeThird'] === "0000") {
        this.p = 1;
        this.workshopMemberClassList = response['data'];
      }
      else {
        this.Toast.fire({
          icon: 'error',
          title: response['msg'],
          timer: 5000
        });

        //強制登出
        sessionStorage.removeItem("WorkshopMemberId");
        this._router.navigate(['/WorkshopMember/Login']);
      }
    });
  }

}
